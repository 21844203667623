import { buildItem, checkArgs } from "../../../utils/DataLayer";
import { getInfoBikeFromUrl, isEmptyObject } from "../../../utils/Utils";
import { eventName } from "../DataLayerContext";
import { buildWBCStepEvent } from "../builder/WBCStepBuilder";

export function pushWBCAddStepEvent(
  {
    itemId = "",
    itemName = "",
    itemPrice = "",
    itemStep = {},
    itemCategory = "",
    quantity = "1",
    equipmentName = "",
    currency = "",
  },
  dispatch
) {
  if (checkArgs([itemName]) && !isEmptyObject(itemStep) && dispatch) {
    const items = [];
    const item = buildItem({
      panelVariantElementID: itemId,
      panelVariantElement: itemName,
      totalPrice: itemPrice,
      stepName: itemStep.name,
      subCategoryName: itemCategory,
      panelQuantity: quantity,
    });
    items.push(item);
    const infoBike = getInfoBikeFromUrl(window.location.pathname);
    const wbcAddStepEvent = buildWBCStepEvent({
      eventName: eventName.clickAddAccessory,
      familyName: infoBike.family,
      modelName: infoBike.model,
      equipmentName,
      stepName: itemStep.name,
      stepOrder: itemStep.position,
      items,
      currency,
    });
    dispatch(wbcAddStepEvent);
  }
}

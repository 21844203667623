import { useLanguageContent } from "../../../context/LanguageContext";
import { useMTO } from "../../../hooks/useMTO";
import { useProgressContent } from "../../../context/ProgressContext";
import {
  DEFAULT_CONFLICTS_MODAL,
  useModalContent,
} from "../../../context/ModalContext";
import defaultImage from "../../../assets/images/DefaultImage.png";
import { useAftermarketMTO } from "../../../hooks/useAftermarketMTO";
import { replacer } from "../../../utils/Utils";
import { useBikeEnrichment } from "../../../context/FamilyMTOContext";
import { useParams } from "react-router-dom";
import { getCompatibleSeats } from "./ConflictState";
import { useMTOContext } from "../../../context/MTOContext";

export const conflictTypes = {
  conflict: "CONFLICT",
};

export function isThereConflict(response, isAccessory = false) {
  if (isAccessory) {
    const consistent = response?.accessoryConfiguration?.consistent;
    return !consistent;
  } else if (response?.bikeConfiguration) {
    const result = response?.bikeConfiguration?.consistent;
    return !result;
  }
  return response?.consistent ? !response.consistent : false;
}

export const checkSeat = (groupId = "") => {
  return groupId.includes("SEAT");
};

export function useConflictData() {
  const { language } = useLanguageContent();
  const { setConflictsModal } = useModalContent();
  const { getBikeEnrichmentByKey } = useBikeEnrichment();
  const { version } = useParams();
  const { getChaItem } = useMTOContext();

  const getConflictSubGroup = (bikeConfiguration) => {
    if (bikeConfiguration?.groups?.length > 0) {
      const { csticGroupsFlat } = bikeConfiguration;
      const firstGroup = csticGroupsFlat[0];
      if (
        firstGroup?.cstics?.length > 0 &&
        firstGroup?.groupType?.includes(conflictTypes.conflict)
      ) {
        return firstGroup;
      }
    }
    return undefined;
  };

  const checkBundle = (key) => {
    return key?.toLowerCase() === "true" || key?.toLowerCase() === "false";
  };

  const buildOptionList = ({
    conflictSubGroup,
    accessory,
    isAccessory = false,
    accessoryEnrichment,
    getSKUByCha,
    isRemove = false,
  }) => {
    const list = [];
    if (isAccessory && conflictSubGroup?.cstics?.length > 0) {
      // SCENARIO DI CONFLITTO IN AGGIUNTA ACCESSORI
      conflictSubGroup.cstics.forEach((item) => {
        if (item.lastValidValue !== accessory.value) {
          const domainValue = item.domainvalues.find(
            (elem) => elem.key === item.lastValidValue
          );
          if (domainValue) {
            const sku = getSKUByCha("", "", domainValue.key);
            const accEnrich =
              sku?.length > 0 ? accessoryEnrichment[sku] : undefined;
            if (accEnrich) {
              list.push({
                attributeKey: domainValue.key,
                groupId: domainValue.key.replace(`@${domainValue.name}`, ""),
                domainValueKey: sku?.length > 0 ? sku : domainValue.key,
                name:
                  accEnrich?.linguaImgSingoli?.productName || domainValue.name,
                value: domainValue.key,
                price: accEnrich?.price?.formattedValue,
                sourceImg:
                  accEnrich?.linguaImgSingoli?.mainImage || defaultImage,
              });
            }
          }
        }
      });
    } else if (conflictSubGroup?.cstics?.length > 0) {
      conflictSubGroup.cstics.forEach((element) => {
        const cardEnrichment = getBikeEnrichmentByKey(
          element?.name || "",
          element?.value || ""
        );
        const cardCha = getBikeEnrichmentByKey(element?.name || "");
        const chaItem = getChaItem(element?.name, element.value);
        const filteredElement = element.domainvalues.filter(
          (domainValue) => domainValue.key === element.value
        )[0];
        list.push({
          cha: cardCha?.value || undefined,
          attributeKey: element.key,
          attributeName: element.langdepname,
          groupId: element.key.replace(`@${element.name}`, ""),
          domainValueKey: checkBundle(filteredElement.key)
            ? element.name
            : filteredElement.key,
          name: checkBundle(filteredElement.key)
            ? element.langdepname
            : cardEnrichment?.value || filteredElement.langdepname,
          value: checkBundle(filteredElement.key)
            ? element.name
            : filteredElement.name,
          price: chaItem?.price?.value > 0 ? chaItem.price.formattedValue : "",
          sourceImg: cardEnrichment?.url || defaultImage,
        });
      });
    }
    return list;
  };

  const conflictCardItem = (optionList, name, isEqual = false) => {
    let conflictItem;
    if (optionList?.length > 0) {
      if (isEqual) {
        conflictItem = optionList.find((item) => item.attributeName === name);
      } else {
        conflictItem = optionList.find((item) => item.attributeName !== name);
      }
    }
    return conflictItem;
  };

  const buildCompatibleSeats = (domainValueKey, conflictSubGroup) => {
    const compatible = [];
    const complatibleSeatKeys = getCompatibleSeats(version, domainValueKey);
    if (
      complatibleSeatKeys?.length > 0 &&
      conflictSubGroup?.cstics?.length > 0
    ) {
      const element = conflictSubGroup.cstics.find(
        (item) => item.value !== domainValueKey
      );
      if (element?.domainvalues?.length > 0) {
        complatibleSeatKeys.forEach((seatKey) => {
          const cardEnrichment = getBikeEnrichmentByKey(
            element?.name || "",
            seatKey || ""
          );
          const cardCha = getBikeEnrichmentByKey(element?.name || "");
          const csticsV = element?.domainvalues.find(
            (item) => item.key === seatKey
          );
          if(csticsV){
          const chaItem = getChaItem(element?.name, seatKey);
          compatible.push({
            cha: cardCha?.value || "",
            attributeKey: element.key,
            attributeName: element.langdepname,
            groupId: element.key.replace(`@${element.name}`, ""),
            domainValueKey: seatKey,
            name: cardEnrichment?.value || csticsV.langdepname,
            value: csticsV.name,
            price:
              chaItem?.price?.value > 0 ? chaItem.price.formattedValue : "",
            sourceImg: cardEnrichment?.url || defaultImage,
          });
        }
        });
      }
    }
    return compatible;
  };

  const showConflictsModal = (bikeConfiguration, isSeat = false) => {
    const conflictSubGroup = getConflictSubGroup(bikeConfiguration);
    const optionList = buildOptionList({ conflictSubGroup });
    const conflictItem = conflictCardItem(optionList, conflictSubGroup.name);
    if (isSeat) {
      const latest = conflictCardItem(optionList, conflictSubGroup.name, true);
      const complatibleSeats = buildCompatibleSeats(
        conflictItem?.domainValueKey,
        conflictSubGroup
      );
      setConflictsModal({
        showModal: true,
        isSeat,
        title:
          language?.configurator_information["modal.conflicts.seat.title"] ||
          "",
        message:
          language?.configurator_information["modal.conflicts.seat.subtitle"] ||
          "",
        incompatibleSeats: {
          latest,
          incoming: conflictItem,
        },
        optionList: complatibleSeats,
      });
    } else {
      setConflictsModal({
        showModal: true,
        title: language?.labels["popup.accessory_interaction.title"] || "",
        message:
          language?.configurator_information["modal.conflicts.message"] || "",
        name: conflictSubGroup.name || "",
        optionList,
        conflictItem,
        isRemove: true,
      });
    }
  };

  const hideConflictsModal = () => {
    setConflictsModal(DEFAULT_CONFLICTS_MODAL);
  };

  const showAccessoryConflictModal = ({
    accessory,
    accEnrich,
    accessoryEnrichment,
    getSKUByCha,
    accessoryConfiguration,
    isRemove = false,
  }) => {
    const conflictSubGroup = getConflictSubGroup(accessoryConfiguration);
    const optionList = buildOptionList({
      conflictSubGroup,
      accessory,
      isAccessory: true,
      accessoryEnrichment,
      getSKUByCha,
      isRemove,
    });
    const conflictItem = {
      attributeKey: accessory.attributeKey,
      groupId: accessory.groupId,
      domainValueKey: accessory.domainValueKey,
      name: accEnrich?.linguaImgSingoli?.productName || "",
      value: accessory.value,
      price: accessory?.price?.formattedValue || "",
      sourceImg: replacer(accEnrich.linguaImgSingoli.mainImage, "\\", "/"),
    };
    setConflictsModal({
      showModal: true,
      title: language?.labels["popup.accessory_interaction.title"] || "",
      message:
        language?.configurator_information["modal.conflicts.message"] || "",
      name: accessory.langDepName || "",
      optionList,
      conflictItem,
      isAccessory: true,
      isRemove,
    });
  };

  const showMandatoryAccessoryModal = ({
    accessory,
    accEnrich,
    mandatoryList = [],
    isRemove = false,
  }) => {
    const conflictItem = {
      attributeKey: accessory.attributeKey,
      groupId: accessory.groupId,
      domainValueKey: accessory.domainValueKey,
      name: accEnrich?.linguaImgSingoli?.productName || "",
      value: accessory.value,
      price: accessory?.price?.formattedValue || "",
      sourceImg: replacer(accEnrich.linguaImgSingoli.mainImage, "\\", "/"),
    };
    const optionList = [];
    mandatoryList.forEach((mandatory) => {
      optionList.push({
        attributeKey: `${mandatory.chr}-${mandatory.chrValue}`,
        groupId: mandatory.categoryDesc,
        domainValueKey: mandatory.sku,
        name: mandatory.linguaImgSingoli.productName,
        value: mandatory.chrValue,
        price: mandatory.price.formattedValue,
        sourceImg: replacer(mandatory.linguaImgSingoli.mainImage, "\\", "/"),
      });
    });
    setConflictsModal({
      showModal: true,
      title:
        language?.configurator_information[
          "steps.aftermarket.modal.messages.slaves.title"
        ] || "",
      name: accessory.langDepName || "",
      optionList,
      conflictItem,
      isAccessory: true,
      isRemove,
      isMandatory: true,
    });
  };

  return {
    showConflictsModal,
    showAccessoryConflictModal,
    showMandatoryAccessoryModal,
    hideConflictsModal,
  };
}

export function useUpdateConflicts() {
  const { hideConflictsModal } = useConflictData();
  const {
    accessoryEnrichment,
    getSonByFatherId,
    updateAccessory,
    updateMandatoryAccessory,
  } = useAftermarketMTO();
  const { getNextSeat, patching, patchValueContext } = useMTO();
  const { progressContent, setProgressContent } = useProgressContent();

  const onCancel = async ({
    conflictItem,
    optionList = [],
    name = "",
    isAccessory = false,
  }) => {
    if (isAccessory) {
      const enrich = accessoryEnrichment[conflictItem.domainValueKey];
      const accessory = getSonByFatherId(conflictItem.domainValueKey, enrich);
      await updateMandatoryAccessory({
        accessory,
        caller: "useUpdateConflicts - onCancel",
        forceRemove: true,
      });
      hideConflictsModal();
    } else {
      const incompatibleItem = optionList.find(
        (item) => item.attributeName !== name
      );
      if (incompatibleItem) {
        setProgressContent({ isLoading: true });
        await patching({
          domainValueKey: null,
          attributeKey: incompatibleItem.attributeKey,
          groupId: incompatibleItem.groupId,
          caller: "ConflictContainer - onCancel",
          selected: true,
        });
        hideConflictsModal();
      }
    }
  };

  const onConfirm = async ({
    optionList = [],
    name = "",
    isAccessory = false,
  }) => {
    if (isAccessory) {
      for (let i = 0; i < optionList.length; i++) {
        const enrich = accessoryEnrichment[optionList[i].domainValueKey];
        const incompatibleItem = getSonByFatherId(
          optionList[i].domainValueKey,
          enrich
        );
        // eslint-disable-next-line no-await-in-loop
        await updateAccessory({
          accessory: incompatibleItem,
          caller: "useUpdateConflicts - onConfirm",
        });
      }
      hideConflictsModal();
    } else {
      setProgressContent({ isLoading: true });
      const lastItemSelected = optionList.find(
        (item) => item.attributeName === name
      );
      if (lastItemSelected) {
        await patching({
          domainValueKey: lastItemSelected.domainValueKey,
          attributeKey: lastItemSelected.attributeKey,
          groupId: lastItemSelected.groupId,
          caller: "ConflictContainer - onConfirm",
          selected: true,
        });
      }
      const incompatibleItem = optionList.find(
        (item) => item.attributeName !== name
      );
      if (incompatibleItem) {
        await patching({
          domainValueKey: incompatibleItem.domainValueKey,
          attributeKey: incompatibleItem.attributeKey,
          groupId: incompatibleItem.groupId,
          caller: "ConflictContainer - onConfirm",
          selected: false,
        });
      }
      hideConflictsModal();
    }
  };

  const onSeatCancel = async (lastItemSelected) => {
    if (lastItemSelected?.domainValueKey) {
      const nextSeat = getNextSeat(lastItemSelected.domainValueKey);
      if (nextSeat) {
        setProgressContent({ isLoading: true });
        await patching({
          domainValueKey: nextSeat.domainValueKey,
          attributeKey: nextSeat.attributeKey,
          groupId: nextSeat.groupId,
          caller: "ConflictContainer - onSeatCancel",
          skipPatch: true,
        });
      }
    }
    hideConflictsModal();
  };
  const onSeatConfirm = async (incoming, selectedOption) => {
    if (incoming && selectedOption) {
      setProgressContent({ isLoading: true });
      patchValueContext(
        incoming?.domainValueKey,
        incoming?.attributeKey,
        incoming?.groupId
      );
      await patching({
        domainValueKey: selectedOption.domainValueKey,
        attributeKey: selectedOption.attributeKey,
        groupId: selectedOption.groupId,
        caller: "ConflictContainer - onSeatConfirm",
      });
    }
    hideConflictsModal();
  };

  return {
    onCancel,
    onConfirm,
    isLoading: progressContent?.isLoading || false,
    onSeatCancel,
    onSeatConfirm,
  };
}

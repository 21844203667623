import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { checkLastModelYear, isEmptyObject, ordinamentoPreconfigByPrice, replacer, scramblerFamilyLabel } from "../utils/Utils";
import NoImageCard from "../assets/images/NoImageCard.png";
import { useLanguageContent } from "./LanguageContext";
import { getEnvImage } from "../utils/environmentImage";

export const FamilyMTOContext = createContext({
  familyMTOComplete: {},
});

let localStateFamily = JSON.parse(localStorage.getItem("familyMTOcontent"));

export function FamilyMTOProvider({ children, props }) {
  const [familyMTOComplete, setFamilyMTOComplete] = useState(props || localStateFamily);
  const [bikeEnrichment, setBikeEnrichment] = useState({});
  // TODO: Detla prices, to be removed when BE is updated
  const [preconfigDeltaPrices, setPreconfigDeltaPrices] = useState({});
  const [preconfigActiveMtoEvn, setPreconfigActiveMtoEvn] = useState({});

  useEffect(() => {
    localStorage.setItem("familyMTOcontent", JSON.stringify(familyMTOComplete));
    localStateFamily = familyMTOComplete;
  }, [familyMTOComplete]);

  const provider = {
    familyMTOComplete,
    setFamilyMTOComplete: selected => {
      const familyMTOComplete = selected;
      setFamilyMTOComplete(familyMTOComplete);
    },
    bikeEnrichment,
    setBikeEnrichment,
    preconfigDeltaPrices,
    setPreconfigDeltaPrices,
    preconfigActiveMtoEvn,
    setPreconfigActiveMtoEvn,
  };

  return <FamilyMTOContext.Provider value={provider}>{children}</FamilyMTOContext.Provider>;
}

export function useFamilyMTOComplete() {
  const { familyMTOComplete, setFamilyMTOComplete, preconfigDeltaPrices, setPreconfigDeltaPrices, preconfigActiveMtoEvn, setPreconfigActiveMtoEvn} = useContext(FamilyMTOContext);
  const { language } = useLanguageContent();

  const searchVersion = useCallback(
    (familyCode, supmCode, prodgrp1) => {
      const family = familyMTOComplete.find(item => item.id === familyCode);
      if (family) {
        const superModel = family.superModelList.find(item => item.id === supmCode);
        if (superModel) {
          const version = superModel.versionList.find(item => item.prodgrp1 === prodgrp1);
          if (version) {
            return version;
          }
        } else {
          for (const sm of family.superModelList) {
            const version = sm.versionList.find(item => item.model === supmCode);
            if (version) {
              return version;
            }
          }
        }
      }
      return undefined;
    },
    [familyMTOComplete]
  );

  const getSuperModelInfo = useCallback(
    (familyCode, supmCode, versionCode = "") => {
      const currentFamily = familyMTOComplete.find(item => item.id === familyCode);
      if (currentFamily) {
        for (const sm of currentFamily.superModelList) {
          const version = sm.versionList.find(item => item.model === supmCode || item.prodgrp1 === versionCode.toUpperCase());
          if (version) {
            const { familyCode } = currentFamily;
            const { matnr } = sm;
            return {
              familyCode,
              matnr,
            };
          }
        }
      }
      return undefined;
    },
    [familyMTOComplete]
  );

  const getFamilyInfo = useCallback(
    family => {
      const currentFamily = familyMTOComplete.find(item => item.id === family);
      if (currentFamily) {
        return {
          id: currentFamily.id,
          familyCode: currentFamily.familyCode,
          falimyLabel: currentFamily.falimyLabel,
          prodLine: currentFamily.prodLine,
          prodLineLabel: currentFamily.prodLineLabel,
        };
      }
      return undefined;
    },
    [familyMTOComplete]
  );

  function getSpecific(version, prodgrp1) {
    if (version) {
      let mtsVersionBase;
      let mtsVersionRestricted;
      let mtsVersionVariant;
      version.base.forEach(v => {
        const temp = v.find(item => item.id === prodgrp1 || item.prodgrp1.toLowerCase() === prodgrp1.toLowerCase());
        if (temp) {
          mtsVersionBase = temp;
        }
      });
      // eslint-disable-next-line max-depth
      if (mtsVersionBase) {
        return mtsVersionBase;
      }
      version.restricted.forEach(v => {
        const temp = v.find(item => item.id === prodgrp1);
        if (temp) {
          mtsVersionRestricted = temp;
        }
      });
      // eslint-disable-next-line max-depth
      if (mtsVersionRestricted) {
        return mtsVersionRestricted;
      }
      version.variants.forEach(v => {
        const temp = v.find(item => item.id === prodgrp1);
        if (temp) {
          mtsVersionVariant = temp;
        }
      });
      // eslint-disable-next-line max-depth
      if (mtsVersionVariant) {
        return mtsVersionVariant;
      }
    }
    return undefined;
  }

  const findSpecificVersion = useCallback(
    (familyCode, supmCode, prodgrp1) => {
      const family = familyMTOComplete?.find(item => item.id === familyCode);
      if (family) {
        const superModel = family.superModelList.find(item => item.id === supmCode);
        if (superModel) {
          let version = superModel.versionList.find(item => item.prodgrp1 === prodgrp1);
          if (!version) {
            version = superModel.versionList.find(item => item.model === supmCode);
          }
          const specific = getSpecific(version, prodgrp1);
          if (specific) {
            return specific;
          }
        } else {
          for (const sm of family.superModelList) {
            const version = sm.versionList.find(item => item.model === supmCode);
            const specific = getSpecific(version, prodgrp1);
            if (specific) {
              return specific;
            }
          }
        }
      }
      return undefined;
    },
    [familyMTOComplete]
  );

  const checkOrigin = useCallback(
    (countryCode = "", languageCode = "") => {
      const validCountry = language?.country?.toLowerCase() === countryCode.toLowerCase();
      const validLanguage = language?.language?.toLowerCase() === languageCode.toLowerCase();
      return validCountry && validLanguage;
    },
    [language]
  );

  const checkFamily = useCallback(
    (countryCode = "", languageCode = "", familyCode = "") => {
      const validOrigin = checkOrigin(countryCode, languageCode);
      const validFamily = familyMTOComplete.find(item => item.id === familyCode) !== undefined;
      return validOrigin && validFamily;
    },
    [checkOrigin, familyMTOComplete]
  );

  const checkSuperModel = useCallback(
    (countryCode = "", languageCode = "", familyCode = "", superModelCode = "") => {
      const validOrigin = checkOrigin(countryCode, languageCode);
      const family = familyMTOComplete.find(item => item.id === familyCode);
      const validFamily = family !== undefined;
      if (validOrigin && validFamily) {
        const superModel = family.superModelList.find(item => item.id === superModelCode);
        if (superModel) {
          return true;
        }
        for (const sm of family.superModelList) {
          const version = sm.versionList.find(item => item.model === superModelCode);
          if (version) {
            return true;
          }
        }
      }
      return false;
    },
    [checkOrigin, familyMTOComplete]
  );

  const searchVariants = useCallback(
    (familyCode, supmCode) => {
      if (familyCode && supmCode) {
        const currentFamily = familyMTOComplete.find(item => item.id === familyCode);
        if (currentFamily) {
          for (const sm of currentFamily.superModelList) {
            const version = sm.versionList.find(item => item.model === supmCode);
            if (version) {
              return version;
            }
          }
        }
      }
      return undefined;
    },
    [familyMTOComplete]
  );

  const checkConfigURL = useCallback(
    (familyCode, supmCode, versionCode) => {
      const version = searchVariants(familyCode, supmCode);
      if (version) {
        const specificVerision = getSpecific(version, versionCode);
        if (specificVerision) {
          return true;
        }
        return false;
      }
      return false;
    },
    [searchVariants]
  );

  const getPreconfigDeltaPrice = useCallback(
    modelCode => {
      if (!modelCode) return "";
      return preconfigDeltaPrices[modelCode] || "";
    },
    [preconfigDeltaPrices]
  );

  const getDefaultEnvState = useCallback(() => {

    const envDefault = {
      id: preconfigActiveMtoEvn?.defaultState?.id,
      DAY: getEnvImage(preconfigActiveMtoEvn?.defaultState?.id)?.DAY,
      NIGHT: getEnvImage(preconfigActiveMtoEvn?.defaultState?.id)?.NIGHT,
    }

      return envDefault;
    },
    [preconfigActiveMtoEvn]
  );

  const getEnvironment = useCallback(
    modelCode => {
      if (!modelCode) return "";
      return preconfigActiveMtoEvn?.versions[modelCode]?.defaultEnv || "";
    },
    [preconfigActiveMtoEvn]
  );

  const getEnvironmentCameras = useCallback(
    envCode => {
      if (!envCode) return "";
      return preconfigActiveMtoEvn?.environmentCameras[envCode]?.cameras || "";
    },
    [preconfigActiveMtoEvn]
  );

  const getEnvironmentList = useCallback(
    modelCode => {
      if (!modelCode) return "";
      const enviroments = preconfigActiveMtoEvn?.versions[modelCode]?.environments;
      const envList = [];

      enviroments?.forEach( element =>{
        const env = {
          id: element?.id,
          DAY: getEnvImage(element?.id)?.DAY,
          NIGHT: getEnvImage(element?.id)?.NIGHT,
        }
        envList.push(env);
    });
      return envList;
    },
    [preconfigActiveMtoEvn]
  );

  return {
    familyMTOComplete,
    setFamilyMTOComplete,
    preconfigDeltaPrices,
    setPreconfigDeltaPrices: (data, country, language) => {
      const dpCountry = data[country.toLowerCase()];
      if (dpCountry) {
        const dpLanguage = dpCountry[language.toLowerCase()] || {};
        setPreconfigDeltaPrices(dpLanguage);
      } else {
        setPreconfigDeltaPrices({});
      }
    },
    setPreconfigActiveMtoEvn,
    getSuperModelInfo,
    searchVersion,
    findSpecificVersion,
    checkOrigin,
    checkFamily,
    checkSuperModel,
    searchVariants,
    getSpecific,
    getFamilyInfo,
    checkConfigURL,
    getPreconfigDeltaPrice,
    getEnvironment,
    getEnvironmentCameras,
    getDefaultEnvState,
    getEnvironmentList
  };
}

export const CHA_GROUPS = {
  PILOT_SEAT_GROUP: "Pilot seat",
  BUNDLE_GROUP: "Bundle",
};

export function useBikeEnrichment() {
  const { bikeEnrichment, setBikeEnrichment } = useContext(FamilyMTOContext);

  const updateBikeEnrichment = responseEnrichment => {
    let enrichmentMTO;
    if (responseEnrichment?.chaList?.length > 0) {
      const chaMap = {};
      responseEnrichment.chaList.forEach(element => {
        chaMap[element.key] = {
          cha: element.cha,
          description: element.description,
          url: element?.url?.length > 0 ? replacer(element.url[0], "\\", "/") : "",
          valueMap: {},
        };
        element.valueList.forEach(value => {
          chaMap[element.key].valueMap[value.key] = { ...value };
          chaMap[element.key].valueMap[value.key].url = value?.url?.length > 0 ? replacer(value.url[0], "\\", "/") : "";
        });
      });
      console.debug("enrichmentMTO", chaMap);
      setBikeEnrichment(chaMap);
      enrichmentMTO = chaMap;
    }
    return enrichmentMTO;
  };

  const getBikeEnrichmentByKey = (chaKey = "", valueKey = "") => {
    if (chaKey.length > 0) {
      const cha = bikeEnrichment[chaKey];
      if (!cha) return undefined;
      if (!isEmptyObject(cha.valueMap) && valueKey.length > 0) {
        for (const value in cha.valueMap) {
          if (value && value.includes(valueKey)) {
            return cha.valueMap[value];
          }
        }
      } else {
        return {
          value: cha.cha,
          description: cha.description,
          url: cha.url,
        };
      }
    }
    return undefined;
  };

  return {
    bikeEnrichment,
    updateBikeEnrichment,
    getBikeEnrichmentByKey,
  };
}

function compareFn(item1, item2) {
  if (item1.unformattedAmount < item2.unformattedAmount) return -1;
  else if (item1.unformattedAmount > item2.unformattedAmount) return 1;
  return 0;
}

function checkInvalidBase(preconfiguration, country) {
  const whiteList = process.env.REACT_APP_MTS_WITHE_LIST.split("|");
  const countryWhiteList = process.env.REACT_APP_MTS_COUNTRY_WITHE_LIST.split("|");
  return !(
    whiteList.includes(preconfiguration?.model_code.toUpperCase()) &&
    preconfiguration?.base_preconfiguration_id === null &&
    (preconfiguration?.preconfiguration === null || preconfiguration?.preconfiguration?.length <= 0) &&
    preconfiguration?.products === null &&
    !countryWhiteList.includes(country)
  );
}

export function buildFamilyList(responseFamilies, country) {
  const familyList = [];
  responseFamilies.forEach(family => {
    const newFamily = {
      id: family.id,
      description: family.description,
      image: family.preview_image,
      superModelList: [],
      familyCode: "",
      falimyLabel: "",
      prodLine: "",
      prodLineLabel: family.name,
    };
    const sortedModels = family.models.sort((m1, m2) => {
      if (m1.name < m2.name) return -1;
      else if (m1.name > m2.name) return 1;
      return 0;
    });
    sortedModels.forEach(model => {
      let newSuperModel = {};
      const superModel = newFamily.superModelList.find(sm => model.name.includes(sm.supmLabel));
      let isThere = false;
      if (superModel) {
        newSuperModel = { ...superModel };
        isThere = true;
      } else {
        newSuperModel = {
          id: model.id,
          matnr: "",
          matnrLabel: "",
          supmCode: "",
          supmLabel: family.id !== scramblerFamilyLabel ? model.name : "Next-Gen Family 800 cc",
          versionList: [],
        };
      }

      ordinamentoPreconfigByPrice(model.preconfigurations);
      model.preconfigurations.forEach(preconfiguration => {
        if (checkLastModelYear(preconfiguration.model_year)) {
          newFamily.familyCode = preconfiguration.family_code;

          let prodgrp1Label = preconfiguration.name;
          if (preconfiguration.family === "superbike") {
            prodgrp1Label = preconfiguration.name.substring(family.name.length);
          } else if (
            preconfiguration.name !== preconfiguration.family &&
            preconfiguration.family !== "monster" &&
            preconfiguration.family !== "desertx" &&
            preconfiguration.family !== "scrambler"
          ) {
            prodgrp1Label = preconfiguration.name.substring(preconfiguration.family.length);
          }
          prodgrp1Label = prodgrp1Label.trim();

          const version = {
            id: preconfiguration.id,
            unformattedAmount: preconfiguration.model_data.price.unformatted_amount,
            currency: preconfiguration.model_data.price.currency,
            prodgrp1: preconfiguration.model_code,
            prodgrp1Label,
            color: {
              name: preconfiguration.colors[0].name,
              image: preconfiguration.colors[0].asset_small,
              currency: preconfiguration.model_data.price.currency,
              price: 0,
            },
            specs: preconfiguration.model_data.specs,
            sku: preconfiguration.model_data.bike_sku,
            image: preconfiguration.preview_image,
            mhp: preconfiguration.mhp,
            mto: false,
            products: preconfiguration.products,
          };

          if (preconfiguration.base_preconfiguration_id === null && checkInvalidBase(preconfiguration, country)) {
            const index = newSuperModel.versionList.findIndex(
              item => item.prodgrp1 === preconfiguration.model_code || item.model === preconfiguration.model
            );
            if (index < 0) {
              const baseVersion = {
                prodgrp1: preconfiguration.model_code,
                prodgrp1Label,
                model: preconfiguration.model,
                base: new Map(),
                variants: new Map(),
                restricted: new Map(),
              };
              if (preconfiguration.restricted) {
                baseVersion.restricted.set(preconfiguration.model_code, [version]);
              } else {
                baseVersion.base.set(preconfiguration.model_code, [version]);
              }
              newSuperModel.versionList.push(baseVersion);
            } else if (preconfiguration.restricted) {
              newSuperModel.versionList[index].prodgrp1 = preconfiguration.model_code;
              const listRestricted = newSuperModel.versionList[index].restricted.get(preconfiguration.model_code) || [];
              if (listRestricted.length > 0) {
                version.color.price = version.unformattedAmount - listRestricted[0].unformattedAmount;
              }
              listRestricted.push(version);
              newSuperModel.versionList[index].restricted.set(preconfiguration.model_code, listRestricted.sort(compareFn));
            } else {
              newSuperModel.versionList[index].prodgrp1 = preconfiguration.model_code;
              const listBase = newSuperModel.versionList[index].base.get(preconfiguration.model_code) || [];
              if (listBase.length > 0) {
                version.color.price = version.unformattedAmount - listBase[0].unformattedAmount;
              }
              listBase.push(version);
              newSuperModel.versionList[index].base.set(preconfiguration.model_code, listBase.sort(compareFn));
            }
          } else if (checkInvalidBase(preconfiguration, country)) {
            const index = newSuperModel.versionList.findIndex(
              item => item.prodgrp1 === preconfiguration.model_code || item.model === preconfiguration.model
            );
            if (index < 0) {
              const variantVersion = {
                prodgrp1: "",
                model: preconfiguration.model,
                prodgrp1Label,
                base: new Map(),
                variants: new Map(),
                restricted: new Map(),
              };
              version.bike_description = preconfiguration.bike_description;
              version.bike_title = preconfiguration.bike_title;
              version.preconfiguration = preconfiguration.preconfiguration;
              version.unformatted_overprice_amount = preconfiguration.model_data.price.unformatted_overprice_amount;
              variantVersion.variants.set(preconfiguration.model_code, [version]);
              newSuperModel.versionList.push(variantVersion);
            } else {
              const listVariants = newSuperModel.versionList[index].variants.get(preconfiguration.model_code) || [];
              if (listVariants.length > 0) {
                version.color.price = version.unformattedAmount - listVariants[0].unformattedAmount;
              }
              version.bike_description = preconfiguration.bike_description;
              version.bike_title = preconfiguration.bike_title;
              version.preconfiguration = preconfiguration.preconfiguration;
              version.unformatted_overprice_amount = preconfiguration.model_data.price.unformatted_overprice_amount;
              listVariants.push(version);
              newSuperModel.versionList[index].variants.set(preconfiguration.model_code, listVariants.sort(compareFn));
            }
          }
        }
      });
      if (!isThere) {
        newFamily.superModelList.push(newSuperModel);
      }
    });
    familyList.push(newFamily);
  });
  return familyList;
}

export function mergeFamilyList(listMTO = [], listMTS = []) {
  const list1 = [...listMTO];
  const list2 = [...listMTS];
  list1.forEach(family1 => {
    const family2 = list2.find(
      item => item.familyCode === family1.zfam || item.prodLineLabel.toLowerCase() === family1.zprodLineLabel.toLowerCase()
    );
    if (family2) {
      family2.falimyLabel = family1.zfamLabel;
      family2.prodLine = family1.zprodLine;
      family1.superModelList.forEach(superModel1 => {
        const superModel2 = family2.superModelList.find(item => item.supmLabel.toLowerCase() === superModel1.matnrLabel.toLowerCase());
        if (superModel2) {
          superModel2.matnr = superModel1.matnr;
          superModel2.matnrLabel = superModel1.matnrLabel;
          superModel2.supmCode = superModel1.zsupm;
          superModel1.versionList.forEach(version1 => {
            const index = superModel2.versionList.findIndex(item => item.prodgrp1 === version1.zprodgrp1);
            let prodgrp1Label = version1.zprodgrp1Label.substring(family1.zprodLineLabel.length);
            prodgrp1Label = prodgrp1Label.trim();
            const newVersion = {
              id: "",
              unformattedAmount: version1.kbetr,
              currency: version1.konwa,
              prodgrp1: version1.zprodgrp1,
              prodgrp1Label,
              color: undefined,
              specs: [],
              sku: undefined,
              image: NoImageCard, // TODO da sostituire appena disponibile
              mhp: false,
              products: undefined,
              mto: true,
            };
            if (index < 0) {
              const baseVersion = {
                prodgrp1: version1.zprodgrp1,
                prodgrp1Label,
                model: version1.zprodgrp1.toLowerCase(),
                base: new Map(),
                variants: new Map(),
                restricted: new Map(),
              };
              baseVersion.base.set(version1.zprodgrp1, [newVersion]);
              superModel2.versionList.push(baseVersion);
            } else {
              newVersion.image = superModel2.versionList[index].base.get(version1.zprodgrp1)[0].image;
              superModel2.versionList[index].base.clear();
              superModel2.versionList[index].variants.clear();
              superModel2.versionList[index].restricted.clear();
              superModel2.versionList[index].base.set(version1.zprodgrp1, [newVersion]);
            }
          });
        } else {
          const newSuperModel = {
            id: "",
            matnr: superModel1.matnr,
            matnrLabel: superModel1.matnrLabel,
            supmCode: superModel1.zsupm,
            supmLabel: superModel1.zsupmLabel,
            versionList: [],
          };
          superModel1.versionList.forEach(version1 => {
            let model = version1.zprodgrp1Label.toLowerCase();
            model = model.replaceAll(" ", "-");
            let prodgrp1Label = version1.zprodgrp1Label.substring(family1.zprodLineLabel.length);
            prodgrp1Label = prodgrp1Label.trim();
            const newVersion = {
              id: "",
              unformattedAmount: version1.kbetr,
              currency: version1.konwa,
              prodgrp1: version1.zprodgrp1,
              prodgrp1Label,
              color: undefined,
              specs: [],
              sku: undefined,
              image: NoImageCard, // TODO da sostituire appena disponibile
              mhp: false,
              products: undefined,
              mto: true,
            };
            const baseVersion = {
              prodgrp1: version1.zprodgrp1,
              prodgrp1Label,
              model,
              base: new Map(),
              variants: new Map(),
              restricted: new Map(),
            };
            baseVersion.base.set(version1.zprodgrp1, [newVersion]);
            newSuperModel.versionList.push(baseVersion);
          });
          family2.superModelList.push(newSuperModel);
        }
      });
    } else {
      const newFamily = {
        id: family1.zprodLineLabel.toLowerCase(),
        description: "",
        image: NoImageCard, // TODO da sostituire appena disponibile
        superModelList: [],
        familyCode: family1.zfam,
        falimyLabel: family1.zfamLabel,
        prodLine: family1.zprodLine,
        prodLineLabel: family1.zprodLineLabel,
      };
      family1.superModelList.forEach(superModel1 => {
        const newSuperModel = {
          id: "",
          matnr: superModel1.matnr,
          matnrLabel: superModel1.matnrLabel,
          supmCode: superModel1.zsupm,
          supmLabel: superModel1.zprodLineLabel,
          versionList: [],
        };
        superModel1.versionList.forEach(version1 => {
          let prodgrp1Label = version1.zprodgrp1Label.substring(family1.zprodLineLabel.length);
          prodgrp1Label = prodgrp1Label.trim();
          const newVersion = {
            id: "",
            unformattedAmount: version1.kbetr,
            currency: version1.konwa,
            prodgrp1: version1.zprodgrp1,
            prodgrp1Label,
            color: undefined,
            specs: [],
            sku: undefined,
            image: NoImageCard, // TODO da sostituire appena disponibile
            mhp: false,
            products: undefined,
            mto: true,
          };
          const baseVersion = {
            prodgrp1: version1.zprodgrp1,
            prodgrp1Label,
            model: "",
            base: new Map(),
            variants: new Map(),
            restricted: new Map(),
          };
          baseVersion.base.set(version1.zprodgrp1, [newVersion]);
          newSuperModel.versionList.push(baseVersion);
        });
        newFamily.superModelList.push(newSuperModel);
      });
      list2.push(newFamily);
    }
  });
  return list2;
}

export function getFamilyList() {
  let list = [];
  if (localStateFamily?.length > 0) {
    list = localStateFamily;
  }
  return list;
}

import React, { useState, createContext, useEffect, useContext } from "react";

export const WebViewModeContext = createContext({
  webViewMode: {},
});

const localStateWebViewMode = JSON.parse(localStorage.getItem("webviewmode"));
const localDealerCode = JSON.parse(sessionStorage.getItem("dealerCode"));

export function WebViewModeProvider({ children, props }) {
  const [webViewMode, setWebViewMode] = useState(props || localStateWebViewMode);
  const [dealerCode, setDealerCode] = useState(props || localDealerCode);

  useEffect(() => {
    localStorage.setItem("webviewmode", JSON.stringify(webViewMode));
  }, [webViewMode]);

  useEffect(() => {
    sessionStorage.setItem("dealerCode", JSON.stringify(dealerCode));
  }, [dealerCode]);

  const provider = {
    webViewMode,
    dealerCode,
    setWebViewMode: selected => {
      setWebViewMode(selected);
    },
    setDealerCode: selected => {
      setDealerCode(selected);
    },
  };

  return <WebViewModeContext.Provider value={provider}>{children}</WebViewModeContext.Provider>;
}

export function useWebViewMode() {
  return useContext(WebViewModeContext);
}

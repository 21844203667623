import { useCallback, useEffect, useState } from "react";
import { useConfiguratorLib } from "../../../components/configuratorMTO/hooks/useConfiguratorLib";
import { useMTOContext } from "../../../context/MTOContext";
import { firstValueFrom } from "rxjs";
import { useFamilyMTOComplete } from "../../../context/FamilyMTOContext";

export const LIGHTING_STATE = {
  DAY: "DAY",
  NIGHT: "NIGHT",
};

export function useEnvironmentsMTO() {
  const configLib = useConfiguratorLib();
  const { state, changeEnvironment, setLightingProfile } = useMTOContext();
  const { getEnvironment, getDefaultEnvState, getEnvironmentList } = useFamilyMTOComplete();

  const [environments, setEnvironments] = useState(getDefaultEnvState);
  const [isNightMode, setIsNightMode] = useState(false);

  useEffect(() => {
    if (state?.modelId) {
      const toUpperVersion = state.modelId.toUpperCase();
      if (getEnvironment(toUpperVersion)) {
        // set default env by version
        changeEnvironment(getEnvironment(toUpperVersion));
        // set properly env list
        const envList = getEnvironmentList(toUpperVersion)
        setEnvironments(envList);
      }
    }
  }, [state?.modelId]);

  const updateEnvironment = async (id) => {
    changeEnvironment(id);
    await firstValueFrom(
      configLib.engineControlService.setActiveEnvironment$(id)
    );
  };

  const toggleDayNight = async (profile) => {
    setLightingProfile(profile);
    const state = profile;
    await firstValueFrom(
      configLib.engineControlService.setEnvironmentState$(state)
    );
  };

  const filterEnvByLightingProfile = useCallback(
    (profile) => {
      const list = [];
      environments.forEach((environment) => {
        list.push({
          id: environment.id,
          sources: environment[profile],
        });
      });
      return list;
    },
    [environments]
  );

  return {
    environments,
    updateEnvironment,
    toggleDayNight,
    isNightMode,
    setIsNightMode,
    filterEnvByLightingProfile,
  };
}

import "reflect-metadata";
import { useBundleContent } from "../context/BundleAccessoriesContext";
import { useError } from "../context/ErrorContext";
import { useMTOContext } from "../context/MTOContext";
import { useDucatiServiceMTO } from "../services/useDucatiServiceMTO";
import { isEmptyObject, replacer } from "../utils/Utils";
import defaultImg from "../assets/images/DefaultImage.png";
import {
  messageType,
  useServiceModal,
} from "../components/modal/service-modal/ServiceModal";
import { useLanguageContent } from "../context/LanguageContext";
import { useMTO } from "./useMTO";
import { useProgressContent } from "../context/ProgressContext";
import { useCallback } from "react";
import { useUpdateSession } from "../components/carousel/carousel-configurator/MTO/hooks/useUpdateSession";
import {
  isThereConflict,
  useConflictData,
} from "../components/modal/conflict-modal-mto/ConflictContainer";
import { pushWBCRemoveStepEvent } from "../context/data-layer/pusher/WBCRemoveStepPusher";
import { steps, useDataLayerDispatch } from "../context/data-layer/DataLayerContext";
import { pushWBCAddStepEvent } from "../context/data-layer/pusher/WBCAddStepPusher";

export const AFTERMARKET_GROUP = "Aftermarket";

export function useAftermarketMTO() {
  const { language } = useLanguageContent();
  const { bundleContent, setBundleContent } = useBundleContent();
  const { state: mtoContent, ...setters } = useMTOContext();
  const { fetchUpdateAccessoryMTO } = useDucatiServiceMTO();
  const { getColorFromCart } = useMTO();
  const { showServiceModal } = useServiceModal();
  const { echoError } = useError();
  const { setProgressContent } = useProgressContent();
  const { updateSession } = useUpdateSession();
  const { showAccessoryConflictModal, showMandatoryAccessoryModal } =
    useConflictData();
  const { dispatch } = useDataLayerDispatch();

  const setAccessoryContent = (
    resAccessories,
    resAccEnrichment,
    accessoryItemTypeList = []
  ) => {
    const accList =
      resAccEnrichment?.accList?.length > 0 ? resAccEnrichment.accList : [];
    // const { accList } = mockAccEnricment;
    if (resAccessories?.accessories?.length > 0) {
      const accessoryList = [];
      const accessoryEnrichment = {};
      resAccessories.accessories.forEach((accessory) => {
        const accEnrich = accList.find((acc) => acc.sku === accessory.sku);
        let hide = accEnrich?.isHiddenB2C || false;
        
        hide = false;
        
        if (accEnrich && !hide) {
          accessoryEnrichment[accessory.sku] = accEnrich;
          const index = accessoryList.findIndex(
            (item) => item.chaKey === accEnrich.categoryCode
          );
          if (index < 0) {
            const AccessoryElement = {
              chaKey: accEnrich.categoryCode,
              valueList: [],
              chaSKUMap: {},
            };
            const accessoryOption = {};
            const variant = accEnrich?.testoSingoli?.parentID
              ? accList.find(
                  (item) =>
                    item?.testoSingoli?.parentID ===
                      accEnrich?.testoSingoli?.parentID &&
                    item.sku !== accessory.sku
                )
              : undefined;
            accessoryOption.domainValueKey =
              variant !== undefined
                ? accEnrich?.testoSingoli?.parentID
                : accessory.sku;
            accessoryOption.included =
              variant !== undefined ? [accessory.sku] : [];
            accessoryOption.variants = {};
            accessoryOption.variants[accessory.sku] = {
              cha: accessory.cha,
              value: accessory.value,
            };
            accessoryOption.type = accessory.type;
            accessoryOption.attributeKey = `${accessory.cha}-${accessory.value}`;
            accessoryOption.groupId = accEnrich.categoryDesc;
            accessoryOption.categoryCode = accEnrich.categoryCode;
            accessoryOption.isSlave = accEnrich.isSlave;
            accessoryOption.cha = accessory.cha;
            accessoryOption.value = accessory.value;
            accessoryOption.images =
              replacer(accEnrich?.linguaImgSingoli?.mainImage, "\\", "/") ||
              defaultImg;
            accessoryOption.langDepName =
              accEnrich.linguaImgSingoli.productName;
            accessoryOption.price = {
              value: accessory?.price || 0,
              formattedValue: accessory?.formattedPrice || "",
              currency: accessory?.currency || "",
            };
            accessoryEnrichment[accessory.sku].price = {
              value: accessory?.price || 0,
              formattedValue: accessory?.formattedPrice || "",
              currency: accessory?.currency || "",
            };
            const indexCart = accessoryItemTypeList.findIndex(
              (item) => item.sku === accessory.sku
            );
            accessoryOption.selected = indexCart >= 0;
            accessoryOption.attributeName = AFTERMARKET_GROUP;
            AccessoryElement.valueList.push(accessoryOption);
            AccessoryElement.chaSKUMap[accessoryOption.attributeKey] =
              accessory.sku;
            const AccessoryOptionAccordion = {};
            AccessoryOptionAccordion.langDepName = accEnrich.categoryDesc;
            AccessoryOptionAccordion.isGroupName = true;
            AccessoryElement.valueList.push(AccessoryOptionAccordion);
            accessoryList.push(AccessoryElement);
          } else {
            const fatherIndex = accEnrich?.testoSingoli?.parentID
              ? accessoryList[index].valueList.findIndex(
                  (item) =>
                    item.domainValueKey === accEnrich?.testoSingoli?.parentID
                )
              : -1;
            if (fatherIndex < 0) {
              const accessoryOption = {};
              const variant = accEnrich?.testoSingoli?.parentID
                ? accList.find(
                    (item) =>
                      item?.testoSingoli?.parentID ===
                        accEnrich?.testoSingoli?.parentID &&
                      item.sku !== accessory.sku
                  )
                : undefined;
              accessoryOption.domainValueKey =
                variant !== undefined
                  ? accEnrich?.testoSingoli?.parentID
                  : accessory.sku;
              accessoryOption.included =
                variant !== undefined ? [accessory.sku] : [];
              accessoryOption.variants = {};
              accessoryOption.variants[accessory.sku] = {
                cha: accessory.cha,
                value: accessory.value,
              };
              accessoryOption.type = accessory.type;
              accessoryOption.attributeKey = `${accessory.cha}-${accessory.value}`;
              accessoryOption.groupId = accEnrich.categoryDesc;
              accessoryOption.categoryCode = accEnrich.categoryCode;
              accessoryOption.isSlave = accEnrich.isSlave;
              accessoryOption.cha = accessory.cha;
              accessoryOption.value = accessory.value;
              accessoryOption.images =
                replacer(accEnrich?.linguaImgSingoli?.mainImage, "\\", "/") ||
                defaultImg;
              accessoryOption.langDepName =
                accEnrich.linguaImgSingoli.productName;
              accessoryOption.price = {
                value: accessory?.price || 0,
                formattedValue: accessory?.formattedPrice || "",
                currency: accessory?.currency || "",
              };
              accessoryEnrichment[accessory.sku].price = {
                value: accessory?.price || 0,
                formattedValue: accessory?.formattedPrice || "",
                currency: accessory?.currency || "",
              };
              const indexCart = accessoryItemTypeList.findIndex(
                (item) => item.sku === accessory.sku
              );
              accessoryOption.selected = indexCart >= 0;
              accessoryOption.attributeName = AFTERMARKET_GROUP;
              accessoryList[index].valueList.push(accessoryOption);
              accessoryList[index].chaSKUMap[accessoryOption.attributeKey] =
                accessory.sku;
            } else {
              if (
                accessoryList[index]?.valueList[fatherIndex]?.included?.length >
                0
              ) {
                accessoryList[index].valueList[fatherIndex].included.push(
                  accessory.sku
                );
              }
              if (accessoryList[index]?.valueList[fatherIndex]?.variants) {
                accessoryList[index].valueList[fatherIndex].variants[
                  accessory.sku
                ] = {
                  cha: accessory.cha,
                  value: accessory.value,
                };
                const attributeKey = `${accessory.cha}-${accessory.value}`;
                accessoryList[index].chaSKUMap[attributeKey] = accessory.sku;
              }
              if (accessoryEnrichment[accessory.sku]) {
                accessoryEnrichment[accessory.sku].price = {
                  value: accessory?.price || 0,
                  formattedValue: accessory?.formattedPrice || "",
                  currency: accessory?.currency || "",
                };
              }
            }
          }
        }
      });
      const newBundleContent = { ...bundleContent };
      const accessoryListSorted = accessoryList.sort((a, b) => {
        if (a.chaKey < b.chaKey) {
          return -1;
        }
        if (a.chaKey > b.chaKey) {
          return 1;
        }
        return 0;
      });
      const accessoryListHybrid = [];
      accessoryListSorted.forEach((chaItem) => {
        const newCha = {
          chaKey: chaItem.chaKey,
          valueList: chaItem.valueList,
          chaSKUMap: chaItem.chaSKUMap,
        };
        newCha.valueList.sort((a, b) => {
          if (a.cha < b.cha) {
            return -1;
          }
          if (a.cha > b.cha) {
            return 1;
          }
          return 0;
        });
        accessoryListHybrid.push(newCha);
      });
      const accessoryFiltered = [];
      accessoryListHybrid.forEach((chaItem) => {
        const newCha = {
          chaKey: chaItem.chaKey,
          valueList: [],
          chaSKUMap: chaItem.chaSKUMap,
        };
        chaItem.valueList.forEach((element) => {
          const duplicateIndex = newCha.valueList.findIndex(
            (item) => item.domainValueKey === element.domainValueKey
          );
          if (duplicateIndex < 0) {
            if (element?.included?.length === 1) {
              element.domainValueKey = element.included[0];
              element.included = [];
            }
            newCha.valueList.push(element);
          }
        });
        accessoryFiltered.push(newCha);
      });
      newBundleContent.accessoryList = accessoryFiltered;
      newBundleContent.accessoryEnrichment = accessoryEnrichment;
      syncCartAftermarket(accessoryItemTypeList, accessoryEnrichment);
      setBundleContent(newBundleContent);
    }
  };

  const getAccessoryList = useCallback(() => {
    if (bundleContent?.accessoryList?.length > 0) {
      return bundleContent?.accessoryList;
    }
    return [];
  }, [bundleContent?.accessoryList]);

  const getAccessoryEnrichment = useCallback(() => {
    if (
      bundleContent?.accessoryEnrichment &&
      !isEmptyObject(bundleContent.accessoryEnrichment)
    ) {
      return bundleContent?.accessoryEnrichment;
    }
    return undefined;
  }, [bundleContent?.accessoryEnrichment]);

  const syncCartAftermarket = (
    accessoryItemTypeList = [],
    accessoryEnrichment
  ) => {
    if (accessoryItemTypeList?.length > 0) {
      accessoryItemTypeList.forEach((accessory) => {
        let accEnrich;
        if (accessoryEnrichment) {
          accEnrich = accessoryEnrichment[accessory.sku];
        }
        const cartObj = {
          groupId: accEnrich?.categoryDesc || "",
          attributeKey: `${accessory.cha}-${accessory.value}`,
          domainValueKey: accessory.sku,
          name: accEnrich?.linguaImgSingoli?.productName || "",
          attributeName: AFTERMARKET_GROUP,
          type: accessory.type,
          price: {
            value: accessory?.price || 0,
            formattedValue: accessory?.formattedPrice || "",
            currency: accessory?.currency || "",
          },
          image: accEnrich?.linguaImgSingoli?.mainImage || defaultImg,
          cha: accessory.cha,
          value: accessory.value,
          isSlave: accEnrich.isSlave,
        };
        setters.synchronizeCart(true, cartObj);
      });
    }
  };

  const buildCartList = (accessoryItemTypeList = [], accessoryEnrichment) => {
    const list = [];
    if (accessoryItemTypeList?.length > 0) {
      accessoryItemTypeList.forEach((accessory) => {
        let accEnrich;
        if (accessoryEnrichment) {
          accEnrich = accessoryEnrichment[accessory.sku];
        }
        const cartObj = {
          groupId: accEnrich?.categoryDesc || "",
          attributeKey: `${accessory.cha}-${accessory.value}`,
          domainValueKey: accessory.sku,
          name: accEnrich?.linguaImgSingoli?.productName || "",
          attributeName: AFTERMARKET_GROUP,
          type: accessory.type,
          price: {
            value: accessory?.price || 0,
            formattedValue: accessory?.formattedPrice || "",
            currency: accessory?.currency || "",
          },
          image: accEnrich?.linguaImgSingoli?.mainImage || defaultImg,
          cha: accessory.cha,
          value: accessory.value,
          isSlave: accEnrich.isSlave,
        };
        list.push(cartObj);
      });
    }
    return list;
  };

  const updateCart = ({ backendResponse }) => {
    if (backendResponse?.accessorySection?.accessoryItemTypeList) {
      const { accessoryItemTypeList } = backendResponse.accessorySection;
      const accessoryEnrichment = getAccessoryEnrichment();
      const list = buildCartList(accessoryItemTypeList, accessoryEnrichment);
      setters.syncCartByList(AFTERMARKET_GROUP, list);
    }
    setters.updateTotalPrice({
      bikePriceFormatted: backendResponse.bikeSection.bikePriceFormatted,
      optionPriceFormatted: backendResponse.bikeSection.optionPriceFormatted,
      accessoryPriceFormatted:
        backendResponse.accessorySection.accessoryPriceFormatted,
      apparelPriceFormatted:
        backendResponse.apparelSection.apparelPriceFormatted,
      subTotalPriceFormatted:
        backendResponse.overallSection.subTotalPriceFormatted,
    });
    setters.updateQuotes(backendResponse);
    setProgressContent({ isLoading: false });
    updateSession();
  };

  const buildMandatoryListToAdd = (
    backendResponse,
    accessoryEnrichment,
    categoryCode,
    chrValue
  ) => {
    const mandatoryList = [];
    const filteredList = [];
    if (
      backendResponse?.accessorySection?.accessoryItemTypeList &&
      backendResponse?.accessoryConfiguration?.groups?.length > 0
    ) {
      const { groups } = backendResponse.accessoryConfiguration;
      groups.forEach((group) => {
        if (group?.attributes?.length > 0) {
          group.attributes.forEach((attribute) => {
            if (attribute?.value && attribute.value !== chrValue) {
              const sku = getSKUByCha(
                categoryCode,
                attribute.name,
                attribute.value
              );
              if (sku?.length > 0) {
                const currentEnrich = accessoryEnrichment[sku];
                mandatoryList.push(currentEnrich);
              }
            }
          });
        }
      });
      // Filter mandatory list
      const { accessoryItemTypeList } = backendResponse.accessorySection;
      mandatoryList.forEach((item) => {
        const index = accessoryItemTypeList.findIndex(
          (acc) => acc.sku === item.sku
        );
        if (index < 0) {
          filteredList.push(item);
        }
      });
    }
    return filteredList;
  };

  const buildMandatoryListToRemove = (
    backendResponse,
    accessoryEnrichment,
    categoryCode
  ) => {
    const mandatoryList = [];
    if (
      backendResponse?.accessorySection?.accessoryItemTypeList?.length > 0 &&
      backendResponse?.accessoryConfiguration?.groups?.length > 0
    ) {
      const { accessoryItemTypeList } = backendResponse.accessorySection;
      const { groups } = backendResponse.accessoryConfiguration;
      accessoryItemTypeList.forEach((item) => {
        groups.forEach((group) => {
          if (group?.attributes?.length > 0) {
            const mandatoryAttribute = group.attributes.find(
              (attribute) => attribute.name === item.cha
            );
            if (mandatoryAttribute && !mandatoryAttribute.value) {
              const sku = getSKUByCha(categoryCode, item.cha, item.value);
              if (sku?.length > 0) {
                const currentEnrich = accessoryEnrichment[sku];
                mandatoryList.push(currentEnrich);
              }
            }
          }
        });
      });
    }
    return mandatoryList;
  };

  function removeAccessoryEvent(item) {
      pushWBCRemoveStepEvent(
        {
          itemId: item?.domainValueKey,
          itemName: item?.name,
          itemPrice: item?.price?.value,
          itemStep: steps.accessories,
          quantity: item?.quantity,
          equipmentName: "",
          currency: item?.price?.currency,
        },
        dispatch
      );
    }

    function addAccessoryEvent(item) {
        pushWBCAddStepEvent(
          {
            itemId: item?.domainValueKey,
            itemName: item?.langDepName,
            itemPrice: item?.price?.value,
            itemStep: steps.accessories,
            itemCategory: "",
            equipmentName: "",
            currency: item?.price?.currency,
          },
          dispatch
        );
      }

  const updateMandatoryAccessory = async ({
    accessory,
    quantity = 1,
    caller,
    forceRemove = false,
  }) => {
    setProgressContent({ isLoading: true });
    const color = getColorFromCart();
    const zcol = color?.domainValueKey || "";
    const zcolRuote = "";
    const isRemove = forceRemove || accIsInCart(accessory);
    const accessoryItem = {
      sku: accessory.domainValueKey,
      cha: accessory.cha,
      value: accessory.value,
      quantity,
      type: accessory.type,
      currency: accessory?.price?.currency,
      formattedPrice: accessory?.price?.formattedValue,
      price: accessory?.price?.value,
    };
    await fetchUpdateAccessoryMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      cid: mtoContent.CID,
      productCode: mtoContent.productCode,
      version: mtoContent.modelId,
      zcol,
      zcolRuote,
      accessoryItem,
      isRemove,
      addToCartOnly: accessory?.isSlave,
    })
      .then((backendResponse) => {
        updateCart({
          backendResponse,
        });
      })
      .catch((error) => {
        echoError({
          error,
          caller: `${caller} - updateMandatoryAccessory - fetchUpdateAccessoryMTO`,
        });
        showServiceModal(messageType.changingFail);
        setProgressContent({ isLoading: false });
      });
  };

  const updateAccessory = async ({
    accessory,
    quantity = 1,
    caller,
    removeSame = false,
  }) => {
    setProgressContent({ isLoading: true });
    const color = getColorFromCart();
    const zcol = color?.domainValueKey || "";
    const zcolRuote = "";
    const isRemove = accIsInCart(accessory);
    const accessoryItem = {
      sku: accessory.domainValueKey,
      cha: accessory.cha,
      value: accessory.value,
      quantity,
      type: accessory.type,
      currency: accessory?.price?.currency,
      formattedPrice: accessory?.price?.formattedValue,
      price: accessory?.price?.value,
    };
    const accessoryEnrichment = getAccessoryEnrichment();
    const currentEnrich = accessoryEnrichment
      ? accessoryEnrichment[accessory.domainValueKey]
      : undefined;
    const mandatoryList = await fetchUpdateAccessoryMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      cid: mtoContent.CID,
      productCode: mtoContent.productCode,
      version: mtoContent.modelId,
      zcol,
      zcolRuote,
      accessoryItem,
      isRemove,
      addToCartOnly: accessory.isSlave,
    })
      .then((backendResponse) => {
        let mandatoryList = [];
        if (isThereConflict(backendResponse, true)) {
          setProgressContent({ isLoading: false });
          showAccessoryConflictModal({
            accessory,
            accEnrich: currentEnrich,
            accessoryEnrichment,
            getSKUByCha,
            accessoryConfiguration: backendResponse.accessoryConfiguration,
            isRemove: true,
          });
        } else {
          const toRemoveList = buildMandatoryListToRemove(
            backendResponse,
            accessoryEnrichment,
            accessory.categoryCode
          );
          if (toRemoveList?.length > 0) {
            mandatoryList = toRemoveList;
          } else {
            mandatoryList = buildMandatoryListToAdd(
              backendResponse,
              accessoryEnrichment,
              accessory.categoryCode,
              accessory.value
            );
          }
          updateCart({
            backendResponse,
          });
        }
        if(isRemove){
          removeAccessoryEvent(accessory);
        }else{
          addAccessoryEvent(accessory);
        }
        return mandatoryList;
      })
      .catch((error) => {
        echoError({
          error,
          caller: `${caller} - updateAccessory - fetchUpdateAccessoryMTO`,
        });
        showServiceModal(messageType.changingFail);
        setProgressContent({ isLoading: false });
        return [];
      });
    if (mandatoryList?.length > 0) {
      if (!removeSame) {
        showMandatoryAccessoryModal({
          accessory,
          accEnrich: currentEnrich,
          mandatoryList,
          isRemove,
        });
      }
      for (let i = 0; i < mandatoryList.length; i++) {
        const accessory = getSonByFatherId(
          mandatoryList[i].sku,
          mandatoryList[i]
        );
        // eslint-disable-next-line no-await-in-loop
        await updateMandatoryAccessory({
          accessory,
          caller: "updateAccessory",
        });
      }
    }
  };

  const getAccessory = (categoryCode = "", sku = "") => {
    let accessory;
    if (
      categoryCode !== "" &&
      sku !== "" &&
      bundleContent?.accessoryList?.length > 0
    ) {
      const category = bundleContent.accessoryList.find(
        (item) => item.chaKey === categoryCode
      );
      if (category) {
        const acc = category?.valueList?.find((item) => {
          let result = item?.domainValueKey === sku;
          if (item?.included?.length > 0) {
            result = item.included.includes(sku);
          }
          return result;
        });
        if (acc) {
          accessory = acc;
        }
      }
    }
    return accessory;
  };

  const getSKUByCha = (categoryCode = "", cha = "", value = "") => {
    let skuAcc = "";
    if (
      categoryCode.length > 0 &&
      cha.length > 0 &&
      value.length > 0 &&
      bundleContent?.accessoryList?.length > 0
    ) {
      const category = bundleContent.accessoryList.find(
        (item) => item.chaKey === categoryCode
      );
      const attributeKey = `${cha}-${value}`;
      skuAcc = category?.chaSKUMap[attributeKey] || "";
    } else if (
      value.length > 0 &&
      !isEmptyObject(bundleContent?.accessoryEnrichment)
    ) {
      for (const skuEnrich in bundleContent.accessoryEnrichment) {
        if (skuEnrich && bundleContent.accessoryEnrichment[skuEnrich]) {
          const accEnrich = bundleContent.accessoryEnrichment[skuEnrich];
          if (accEnrich.chr === cha && accEnrich.chrValue === value) {
            skuAcc = accEnrich.sku;
          } else if (accEnrich.chrValue === value) {
            skuAcc = accEnrich.sku;
          }
        }
      }
    }
    return skuAcc;
  };

  const accIsInCart = (accessory) => {
    let result = false;
    if (accessory) {
      if (accessory?.included?.length > 0) {
        const accessoryEnrichment = getAccessoryEnrichment();
        accessory.included.forEach((sku) => {
          const accEnrich = accessoryEnrichment[sku];
          const isIn = setters.isThereInCart(
            accessory.attributeName,
            `${accEnrich?.chr}-${accEnrich?.chrValue}`,
            sku
          );
          if (isIn) {
            result = isIn;
          }
        });
      } else {
        result = setters.isThereInCart(
          accessory.attributeName,
          accessory.attributeKey,
          accessory.domainValueKey
        );
      }
    }
    return result;
  };

  const findSameCha = (accessory) => {
    const isInCart = accIsInCart(accessory);
    if (mtoContent?.cart?.groupedItemMap?.size > 0 && !isInCart) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const valueMap = cartMap.get(accessory.attributeName);
      if (valueMap?.size > 0) {
        return valueMap.values().find((item) => item.cha === accessory.cha);
      }
      return undefined;
    }
    return undefined;
  };

  const getSonByFatherId = (sku = "", accEnrich) => {
    const accessory = getAccessory(accEnrich.categoryCode, sku);
    const variant = accessory.variants[sku];
    const newAccessory = {
      domainValueKey: sku,
      included: accessory.included,
      type: accessory.type,
      groupId: accessory.groupId,
      attributeName: accessory.attributeName,
      langDepName: accessory.langDepName,
      selected: accessory.selected,
      attributeKey: `${variant?.cha}-${variant?.value}`,
      cha: variant?.cha,
      value: variant?.value,
      price: accEnrich?.price,
      images: replacer(accEnrich?.linguaImgSingoli?.mainImage, "\\", "/"),
      categoryCode: accessory.categoryCode,
      isSlave: accessory.isSlave,
    };
    return newAccessory;
  };

  const handleClickAccessory = async (accessory) => {
    const sameCha = findSameCha(accessory);
    if (sameCha) {
      // remove prev accessory
      await updateAccessory({
        accessory: sameCha,
        caller: "useAftermarketMTO",
        removeSame: true,
      });
      // add new accessory
      await updateAccessory({
        accessory,
        caller: "useAftermarketMTO",
      });
    } else {
      await updateAccessory({
        accessory,
        caller: "useAftermarketMTO",
      });
    }
  };

  const getSonFromCart = (accessory) => {
    let result;
    if (accessory) {
      if (accessory?.included?.length > 0) {
        const accessoryEnrichment = getAccessoryEnrichment();
        accessory.included.forEach((sku) => {
          const accEnrich = accessoryEnrichment[sku];
          const son = getSonByFatherId(sku, accEnrich);
          const isIn = setters.isThereInCart(
            son.attributeName,
            son.attributeKey,
            sku
          );
          if (isIn) {
            son.included = accessory.included;
            result = son;
          }
        });
      }
    }
    return result;
  };

  return {
    setAccessoryContent,
    accessoryList: getAccessoryList(),
    accessoryEnrichment: getAccessoryEnrichment(),
    syncCartAftermarket,
    updateAccessory,
    getAccessory,
    findSameCha,
    getSonByFatherId,
    accIsInCart,
    handleClickAccessory,
    getSonFromCart,
    updateMandatoryAccessory,
  };
}

import { Configurator } from "@mhp-immersive-exp/sdk";
import { ConfigurationSessionRemoteStrategy } from "@mhp-immersive-exp/sdk/configuration/session/remote";
import { ProductDataBackend } from "@mhp-immersive-exp/sdk/product-data";
import { EngineControlLocalStrategy } from "@mhp-immersive-exp/sdk/engine-control/local";
import { PixelStreamingClient } from "@mhp-immersive-exp/sdk/streaming/pixelstreaming";

export function useConfiguratorLib(config) {
  let configLib;
  if (config) {
    configLib = Configurator.getInstance(config);
  } else {
    configLib = Configurator.getInstance(defaultConfig);
  }

  return configLib;
}

const URL = process.env.REACT_APP_API_SLIM_SDK_MHP;

export const AllowedImageResolutions = {
  res3840: 3840,
  res2800: 2800,
  res1920: 1920,
  res768: 768,
  res480: 480,
};

export const defaultConfig = {
  baseUrl: URL.toString(),
  project: "DUCATI",
  secure: true,
  pathSegments: {
    iod: "iod",
    ruleEngine: "ruler",
    productData: "products",
    shortCode: "url-shortener",
    matchmaker: "matchmaker",
    reservation: "reservation",
    instances: "instances",
    signaling: "signalingserver",
  },
  imageService: {
    resolution: AllowedImageResolutions.res2800,
    previewResolution: AllowedImageResolutions.res2800,
    thumbnailResolution: AllowedImageResolutions.res480,
    srcsetResolutions: [
      AllowedImageResolutions.res480,
      AllowedImageResolutions.res1920,
      AllowedImageResolutions.res2800,
      AllowedImageResolutions.res3840,
    ],
    defaultCameraType: "Ext",
    defaultAnimations: [],
    turntableCameraId: "path_turntablecamera",
    turntableDegreeSteps: 20,
  },
  productData: { strategy: ProductDataBackend },
  engineControl: { strategy: EngineControlLocalStrategy },
  streaming: {
    type: PixelStreamingClient,
  },
  productConfiguration: {
    forceDeprecatedConfigurationUpdateBehavior: false,
    strategy: ConfigurationSessionRemoteStrategy,
  },
  visualizationService: {
    considerActiveConfiguration: false,
    considerActiveEnvironment: false,
    considerActiveAnimations: false,
    iodCameraTypes: ["Ext", "Int"],
  },
};

export function getTargetSrc(image, target) {
  let src = "";
  if (Array.isArray(image?.sources)) {
    const source = image.sources.find(item => item.targetWidth === target);
    src = source.url;
  } else if (typeof image?.sources === "string") {
    src = image?.sources;
  }
  return src;
}

export const allowedDegrees = (degStep = 20, step = 20) => {
  const list = [];
  for (let i = 0; i < step; i += degStep) {
    list.push(i);
  }
  return list;
};

import MountainRoadDayImage from "../assets/images/carousel/Mountain_Road_Day.png";
import MountainRoadNightImage from "../assets/images/carousel/Mountain_Road_Night.png";
import StudioDayImage from "../assets/images/carousel/Studio_Day.png";
import StudioNightImage from "../assets/images/carousel/Studio_Night.png";
import OffroadDayImage from "../assets/images/carousel/Offroad_Day.png";
import OffroadNightImage from "../assets/images/carousel/Offroad_Night.png";
import PiazzaDayImage from "../assets/images/carousel/Piazza_Day.png";
import PiazzaNightImage from "../assets/images/carousel/Piazza_Night.png";
import RacetrackDaytImage from "../assets/images/carousel/Racetrack_Day.png";
import RacetrackNightImage from "../assets/images/carousel/Racetrack_Night.png";


export const getEnvImage  = envName => {
  const images = {
    DAY: "",
    NIGHT: ""
  };

  switch(envName){
    case "env_studio":
      images.DAY=StudioDayImage;
      images.NIGHT=StudioNightImage;
      return images;
    case "env_mountainroad":
      images.DAY=MountainRoadDayImage;
      images.NIGHT=MountainRoadNightImage;
      return images;
    case "env_racetrack":
      images.DAY=RacetrackDaytImage;
      images.NIGHT=RacetrackNightImage;
      return images;
    case "env_urban":
      images.DAY=PiazzaDayImage;
      images.NIGHT=PiazzaNightImage;
      return images;
    case "env_offroad":
      images.DAY=OffroadDayImage;
      images.NIGHT=OffroadNightImage;
      return images;
    default: return "";
  }

}

import { useParams, useSearchParams } from "react-router-dom";
import { useUpdateSession } from "../components/carousel/carousel-configurator/MTO/hooks/useUpdateSession";
import { checkSeat, isThereConflict, useConflictData } from "../components/modal/conflict-modal-mto/ConflictContainer";
import { messageType, useServiceModal } from "../components/modal/service-modal/ServiceModal";
import { useError } from "../context/ErrorContext";
import { useLanguageContent } from "../context/LanguageContext";
import { useMTOContext } from "../context/MTOContext";
import { useNavbarConfigurator } from "../context/NavbarConfiguratorContext";
import { useProgressContent } from "../context/ProgressContext";
import { useRemoveItem } from "../layouts/content/configurator-step-mto/apparels/gallery/apparel-details/RemoveApparel";
import { useDucatiServiceMTO } from "../services/useDucatiServiceMTO";
import { APPARELS_GROUP } from "../utils/Apparels";
import { CHA_GROUPS, useFamilyMTOComplete } from "../context/FamilyMTOContext";
import { getInfoBikeFromUrl } from "../utils/Utils";
import { buildBikeItems, buildItemsFromCartMTO } from "../utils/DataLayer";
import { buildWBCHopOnEventMTO } from "../context/data-layer/builder/WBCHopOnBuilder";
import { useDataLayerDispatch, WBCModelTags } from "../context/data-layer/DataLayerContext";

export function useMTO() {
  const { getSuperModelInfo } = useFamilyMTOComplete();
  const { setProgressContent } = useProgressContent();
  const { language } = useLanguageContent();
  const { navbarContent, setNavbarContent } = useNavbarConfigurator();
  const { showServiceModal } = useServiceModal();
  const { state: mtoContent, ...setters } = useMTOContext();
  const { singleRemove } = useRemoveItem();
  const { echoError } = useError();
  const { updateSession } = useUpdateSession();
  const { patchMTO } = useDucatiServiceMTO();
  const { family, supermodel, version } = useParams();
  const { showConflictsModal } = useConflictData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dispatch } = useDataLayerDispatch();

  async function patching({ attributeKey, domainValueKey, selected, groupId, caller, skipPatch = false }) {
    const domainValueKeyPatch = domainValueKey;
    if (attributeKey.includes("BUNDLE")) {
      if (selected) {
        domainValueKey = "FALSE";
      } else {
        domainValueKey = "TRUE";
      }
    }
    const smInfo = getSuperModelInfo(family, supermodel, version);
    await patchMTO(
      language.country.toLowerCase(),
      language.language.toLowerCase(),
      mtoContent.CID,
      mtoContent.configId,
      smInfo.matnr,
      attributeKey,
      domainValueKey,
      groupId
    )
      .then(backendResponse => {
        if (isThereConflict(backendResponse)) {
          const isSeat = checkSeat(groupId);
          showConflictsModal(backendResponse.bikeConfiguration, isSeat);
        } else {
          // TODO MTO enable only when backend returns complete true
          // setters.setComplete(backendResponse.bikeConfiguration.complete);
          if (!skipPatch) {
            setters.setPatchValue(domainValueKeyPatch, attributeKey, groupId);
          }
          setters.setAdd2Cart(true);
          if (
            backendResponse?.bikeSection &&
            backendResponse?.accessorySection &&
            backendResponse?.apparelSection &&
            backendResponse?.overallSection
          ) {
            setters.updateTotalPrice({
              bikePriceFormatted: backendResponse.bikeSection.bikePriceFormatted,
              optionPriceFormatted: backendResponse.bikeSection.optionPriceFormatted,
              accessoryPriceFormatted: backendResponse.accessorySection.accessoryPriceFormatted,
              apparelPriceFormatted: backendResponse.apparelSection.apparelPriceFormatted,
              subTotalPriceFormatted: backendResponse.overallSection.subTotalPriceFormatted,
            });
            setters.updateQuotes(backendResponse);
          }
          updateSession();
        }
        setProgressContent({ isLoading: false });
      })
      .catch(error => {
        setProgressContent({ isLoading: false });
        echoError({
          error,
          caller: `${caller} - patchMTO`,
        });
        showServiceModal(messageType.changingFail);
      });
  }

  async function patchConfig({ domainValueKey, attributeKey, groupId, selected, caller = "MTOContext", skipPatch = false }) {
    setProgressContent({ isLoading: true });
    const attributeKeyToLower = attributeKey.toLowerCase();
    const apparelsGroupToLower = APPARELS_GROUP.toLowerCase();
    if (attributeKeyToLower.includes(apparelsGroupToLower)) {
      singleRemove({
        itemId: domainValueKey,
        updateProgressBar: () => {
          setProgressContent({ isLoading: false });
        },
      });
    } else {
      await patching({
        attributeKey,
        domainValueKey,
        selected,
        groupId,
        caller,
        skipPatch,
      });
    }
  }

  const pathToRecap = () => {
    const infoBike = getInfoBikeFromUrl(window.location.pathname);
    let pathname = `/bikes/${language.country.toLowerCase()}/${language.language.toLowerCase()}/${infoBike.family}/${infoBike.model}/${
      infoBike.bikeId
    }/recap?cid=${mtoContent.CID}`;
    
    const enableTake = searchParams.get("fromConfiguration") || false;
    if (enableTake) {
      pathname = pathname.concat("&fromConfiguration=true");
    }
    
    return pathname;
  };

  const pathToConfigurator = () => {
    const infoBike = getInfoBikeFromUrl(window.location.pathname);
    let pathname = `/bikes/${language.country.toLowerCase()}/${language.language.toLowerCase()}/${infoBike.family}/${infoBike.model}/${
      infoBike.bikeId
    }?cid=${mtoContent.CID}`;
    
    const enableTake = searchParams.get("fromConfiguration") || false;
    if (enableTake) {
      pathname = pathname.concat("&fromConfiguration=true");
    }
    
    return pathname;
  };

  const modifyItem = tabName => {
    const tab = navbarContent?.navList.find(item => item.group === tabName);
    if (tab && navbarContent?.currentNavItem) {
      const dif = navbarContent.currentNavItem.position - navbarContent.currentNavItem.scollToRefIndex;
      let nextIndex;
      if (dif === 0) {
        nextIndex = tab.position;
      } else {
        nextIndex = tab.position - 1;
      }
      const next = { ...tab };
      next.scollToRefIndex = nextIndex;
      const newNavbarContent = { ...navbarContent };
      newNavbarContent.currentNavItem = next;
      setNavbarContent(newNavbarContent);
    }
  };

  const getFamilyListByCountry = (responseHierarchy, country = "") => {
    let list = [];
    if (responseHierarchy?.countryList?.length > 0) {
      const { countryList } = responseHierarchy;
      const hierarchy = countryList.find(item => item.country.toLowerCase().includes(country));
      if (hierarchy?.familyList?.length > 0) {
        list = hierarchy.familyList;
      }
    }
    return list;
  };

  const getColorFromCart = () => {
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const colorMap = cartMap.get("Color");
      if (colorMap?.size > 0) {
        const iterator1 = colorMap.values();
        return iterator1.next().value;
      }
    }
    return undefined;
  };

  const getNextSeat = domainValueKey => {
    let nextSeat;
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const valueMap = cartMap.get(CHA_GROUPS.PILOT_SEAT_GROUP);
      if (valueMap?.size > 0) {
        for (const v of new Set(valueMap.values())) {
          if (v.domainValueKey !== domainValueKey) {
            nextSeat = v;
          }
        }
      }
    }
    return nextSeat;
  };

  const patchValueContext = (domainValueKey = "", attributeKey = "", groupId = "") => {
    if (domainValueKey?.length > 0 && attributeKey?.length > 0 && groupId?.length > 0) {
      setters.setPatchValue(domainValueKey, attributeKey, groupId);
    }
  };

  function pushWBCHopOnEvent() {
    const bikeDataItem = buildBikeItems({
      bikeName: mtoContent?.superModelCode,
      bikeId: mtoContent?.CID,
      bikePrice: mtoContent?.cart?.bikePriceFormatted,
      bikeBrand: mtoContent?.modelName,
      itemCategory: WBCModelTags.itemCategory,
      familyName: mtoContent?.familyName,
      quantity: 1,
    });
    const items = [bikeDataItem, ...buildItemsFromCartMTO(mtoContent?.cart)];
    const wbcHopOnEvent = buildWBCHopOnEventMTO({
      familyName: mtoContent?.familyName,
      modelName: mtoContent?.modelName,
      equipmentName: "",
      cid: mtoContent?.CID,
      items,
      totalConfigurationValue: mtoContent.cart.subTotalPriceFormatted,
      currency: mtoContent?.currencyIso,
    });
    dispatch(wbcHopOnEvent);
  }

  return {
    patching,
    patchConfig,
    pathToRecap,
    modifyItem,
    pathToConfigurator,
    getFamilyListByCountry,
    getColorFromCart,
    getNextSeat,
    patchValueContext,
    pushWBCHopOnEvent,
  };
}

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isEmptyObject, isWebViewMode, sessionStorageKey } from "../../../utils/Utils";
import { endpointResolver } from "../../../utils/EndpointResolver";
import { LanguageContext, Text, useLanguageContent } from "../../../context/LanguageContext";
import Authorization from "../../authorization/Authorization";
import style from "../Modal.module.scss";
import css from "./SaveMyDucatiModal.module.scss";
import { useWebViewMode, WebViewModeContext } from "../../../context/WebViewModeContext";
import { useModalContent } from "../../../context/ModalContext";
import { Modal } from "bootstrap";
import CloseButtonModal from "../../../assets/images/close_button_modal.svg";
import { dealerDataFromJWT, userDataFromJWT } from "../../../utils/Auth";
import { useAuth } from "react-oauth2-pkce";
import { fetchSaveOnMyDucati, fetchSendToEmail, fetchVerifyRecaptcha } from "../../../services/DucatiService";
import { useError } from "../../../context/ErrorContext";
import { useInit } from "../../../context/InitContext";
import ProgressBarSlider from "../../carousel/carousel-configurator/ProgressBarSlider";
import { cn } from "../../../utils/cn";
import { useScrambler } from "../../../hooks/useScrambler";
import { useDucatiServiceMTO } from "../../../services/useDucatiServiceMTO";
import { useMTOContext } from "../../../context/MTOContext";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const SaveMyDucatiModalId = "save-my-ducati-modal-id";

export function useSaveModal() {
  const { language } = useLanguageContent();
  const { initSessionContent } = useInit();
  const { authService } = useAuth();
  const { setSaveModal } = useModalContent();
  const [progressBar, setProgressBar] = useState(false);
  const [emailProgressBar, setEmailProgressBar] = useState(false);
  const { echoError } = useError();
  const { fetchSendToEmailMTO } = useDucatiServiceMTO();
  const { isMTOMHP, mtoCID } = useMTOContext();
  const [isSaved, setIsSaved] = useState(false);
  const { dealerCode } = useWebViewMode();

  const showSaveModal = () => {
    setSaveModal({
      showSaveModal: true,
      hasError: false,
    });
  };

  const showSaveErrorModal = () => {
    setSaveModal({
      showSaveModal: true,
      hasError: true,
    });
  };

  const hideSaveModal = () => {
    setIsSaved(false);
    setSaveModal({
      showSaveModal: false,
      hasError: false,
    });
  };

  const validateEmail = email => {
    if (email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    return false;
  };

  const enableSend = (email, challengeId) => {
    return validateEmail(email) && challengeId?.length > 0;
  };

  const retrySave = () => {
    const userData = userDataFromJWT(authService);
    if (!isEmptyObject(userData)) {
      setProgressBar(true);
      fetchSaveOnMyDucati(
        language.country.toLowerCase(),
        language.language.toLowerCase(),
        initSessionContent.session.cid,
        userData.email,
        dealerDataFromJWT(authService),
        dealerCode
      )
        .then(() => {
          setProgressBar(false);
          setSaveModal({
            showSaveModal: true,
            hasError: false,
          });
        })
        .catch(error => {
          echoError({
            error,
            caller: "RecapContent - fetchSaveOnMyDucati",
          });
          setProgressBar(false);
        });
    }
  };

  const sendToEmail = (email, challengeId = "") => {
    if (enableSend(email, challengeId)) {
      setEmailProgressBar(true);
      if (isMTOMHP) {
        fetchSendToEmailMTO(mtoCID, email)
          .then(response => {
            setEmailProgressBar(false);
            setIsSaved(true);
          })
          .catch(error => {
            setEmailProgressBar(false);
            setIsSaved(false);
            echoError({
              error,
              caller: "fetchSendToEmailMTO",
            });
          });
      } else {
        fetchSendToEmail(
          language.country.toLowerCase(),
          language.language.toLowerCase(),
          initSessionContent.session.cid,
          email,
          challengeId,
          dealerCode
        )
          .then(() => {
            setEmailProgressBar(false);
            setIsSaved(true);
          })
          .catch(error => {
            setEmailProgressBar(false);
            setIsSaved(false);
            echoError({
              error,
              caller: "fetchSendToEmail",
            });
          });
      }
    }
  };

  const retrieveGoogleScore = async token => {
    const googleScore = await fetchVerifyRecaptcha({
      token,
      siteKey: process.env.GOOGLE_RECAPTCHA_KEY,
      apiKey: process.env.GOOGLE_RECAPTCHA_API_KEY,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        echoError({
          error,
          caller: "retrieveGoogleScore - fetchVerifyRecaptcha",
        });
        return undefined;
      });
    return googleScore;
  };

  return {
    progressBar,
    emailProgressBar,
    showSaveModal,
    showSaveErrorModal,
    hideSaveModal,
    retrySave,
    enableSend,
    sendToEmail,
    isSaved,
    retrieveGoogleScore,
  };
}

function SaveMyDucatiModalBody({ hasError = false }) {
  const { webViewMode } = useContext(WebViewModeContext);
  const { language } = useContext(LanguageContext);
  const [isLogged, setIsLogged] = useState(false);
  const { hideSaveModal, retrySave, enableSend, sendToEmail, progressBar, emailProgressBar, isSaved, retrieveGoogleScore } = useSaveModal();
  const { scramblerStyle } = useScrambler();
  const [emailData, setEmailData] = useState("");
  const [challengeId, setChallengeId] = useState("");

  useEffect(() => {
    if (sessionStorage && sessionStorage?.length > 0) {
      setIsLogged(sessionStorage.getItem(sessionStorageKey.isAuthorizedKey));
    }
  }, [sessionStorage]);

  function loggedModal() {
    if (hasError) {
      return saveModal(
        language.labels["popup.save_configuration.text_error"],
        "",
        language.labels["popup.save_configuration.btn_retry"],
        language.labels["configurator.exit.btn_continue"]
      );
    }
    return saveModal(
      language.labels["popup.save_configuration.auth.saved.title"],
      language.labels["popup.save_configuration.auth.saved.text"],
      language.labels["popup.save_configuration.auth.saved.my_configuration"],
      language.labels["configurator.exit.btn_continue"]
    );
  }

  function notLoggedModal() {
    return saveModal(
      language.save_my_ducati_configuration["popup.save_configuration.auth.title"],
      language.save_my_ducati_configuration["popup.save_configuration.auth.description"],
      language.labels["auth.register"],
      language.labels["auth.login"]
    );
  }

  function notLoggedSavedModal() {
    return saveModal(
      language.save_my_ducati_configuration["popup.save_configuration.send.success"],
      "",
      "",
      language.labels["configurator.exit.btn_continue"]
    );
  }

  function emailChangeHandler(e) {
    setEmailData(e.target.value);
  }

  const onVerifyReCAPTCHA = useCallback(async token => {
    if (token) {
      await retrieveGoogleScore(token);
      setChallengeId(token);
    } else {
      setChallengeId("");
    }
  }, []);

  function saveModal(title, description, btnLeftLabel, btnRightLabel) {
    const templateUrl = !isLogged ? process.env.REACT_APP_URL_REGISTER : process.env.REACT_APP_MY_DUCATI_CONFIGURATIONS;
    const url = endpointResolver(templateUrl, language.country.toLowerCase(), language.language.toLowerCase());

    return (
      <div className={`modal-content ${style.content}`}>
        <div className="modal-header border-0 d-flex justify-content-end">
          <button
            tabIndex="0"
            type="button"
            className="btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              hideSaveModal();
            }}
          >
            <img src={CloseButtonModal} alt="CloseButtonModal" />
          </button>
        </div>
        <div className={`modal-body p-0 ps-lg-0 ${style["max-height"]} modal-dialog-centered`}>
          <div className={"container-fluid mx-0 text-center ".concat(css["save-myducati-modal-container"])}>
            <div className="row mx-0 px-0">
              <p className={"".concat(css["save-myducati-modal-title"])}>{title}</p>
            </div>
            <div className="row mx-0 px-0">
              <p className={"".concat(css["save-myducati-modal-desc"])}>{description}</p>
            </div>
            <div className={"row mx-0 px-0 ".concat(css["save-myducati-modal-btn-container"])}>
              {!isWebViewMode(webViewMode) &&
                (hasError ? (
                  <div className={"col mx-0 ".concat(style["save-myducati-modal-btn-left"])}>
                    {progressBar ? (
                      <div className="h-100 d-flex align-items-center">
                        <ProgressBarSlider customStyle="w-100" />
                      </div>
                    ) : (
                      <button
                        className={`text-decoration-none ${style["btn-transparent"]}`}
                        onClick={() => {
                          retrySave();
                        }}
                        type="button"
                      >
                        {btnLeftLabel}
                      </button>
                    )}
                  </div>
                ) : (
                  btnLeftLabel?.length > 0 && (
                    <div className={"col mx-0 ".concat(css["save-myducati-modal-btn-left"])}>
                      <a className={`text-decoration-none ${css["btn-transparent"]}`} href={url} target="_blank" rel="noreferrer">
                        {btnLeftLabel}
                      </a>
                    </div>
                  )
                ))}
              <div className={"col mx-0 ".concat(css["save-myducati-modal-btn-right"])}>
                {!isLogged && !isSaved ? (
                  <Authorization logInlabel={btnRightLabel} noBtnStyle={true} />
                ) : (
                  <button
                    type="button"
                    className={cn({
                      [style["btn-red"]]: !scramblerStyle,
                      [style["btn-yellow"]]: scramblerStyle,
                      [css.btn_saved]: isSaved,
                    })}
                    data-bs-dismiss="modal"
                    onClick={() => {
                      hideSaveModal();
                    }}
                  >
                    {btnRightLabel}
                  </button>
                )}
              </div>
            </div>
            {!isLogged && !isSaved && (
              <div className="mt-5 text-center">
                <p className={css["save-myducati-modal-email-descrption"]}>
                  <Text tid="popup.save_configuration.text" />
                </p>

                <div className={cn("d-flex justify-content-center align-items-center", css["send-mail-container"])}>
                  <input
                    type="text"
                    placeholder="Email"
                    className={cn(css["save-myducati-modal-input"], "px-3")}
                    onChange={emailChangeHandler}
                  />
                  {emailProgressBar ? (
                    <ProgressBarSlider customStyle={cn("ms-lg-3", "mt-3", css["email-progress-bar"])} />
                  ) : (
                    <button
                      className={cn(
                        {
                          [css["btn-feature-grey"]]: !enableSend(emailData, challengeId) && !scramblerStyle,
                          [css["btn-disabled-scrambler"]]: !enableSend(emailData, challengeId) && scramblerStyle,
                          [css["btn-red"]]: enableSend(emailData, challengeId) && !scramblerStyle,
                          [css["btn-yellow"]]: enableSend(emailData, challengeId) && scramblerStyle,
                        },
                        css["save-myducati-modal-email-btn"],
                        "ms-lg-3",
                        "mt-3",
                        "mt-lg-0"
                      )}
                      type="button"
                      disabled={!enableSend(emailData, challengeId)}
                      onClick={() => sendToEmail(emailData, challengeId)}
                    >
                      <Text tid="popup.share.btn_send" />
                    </button>
                  )}
                </div>

                <div className="container-fluid d-flex justify-content-center mt-3">
                  <GoogleReCaptcha onVerify={onVerifyReCAPTCHA} />
                </div>

                <p className="mt-3">
                  <a
                    className={css["save-myducati-modal-email-link"]}
                    href={endpointResolver(process.env.REACT_APP_PRIVACY, language.country.toLowerCase(), language.language.toLowerCase())}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text tid="configurator.edit.label_privacy" />
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderSaveMyDucatiModal() {
    return !isLogged ? (isSaved ? notLoggedSavedModal() : notLoggedModal()) : loggedModal();
  }

  return renderSaveMyDucatiModal();
}

export function SaveMyDucatiModal() {
  const modalRef = useRef(null);
  const { saveModal } = useModalContent();

  useEffect(() => {
    if (!modalRef.current) return;
    const modalChild = new Modal(modalRef?.current);
    if (modalChild) {
      if (saveModal?.showSaveModal) {
        modalChild.show();
      } else {
        modalChild.hide();
      }
    }
  }, [saveModal]);

  return (
    <div
      ref={modalRef}
      className={`modal fade px-0 ${style.background}`}
      id={SaveMyDucatiModalId}
      tabIndex="-1"
      aria-labelledby={SaveMyDucatiModalId}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GOOGLE_RECAPTCHA_KEY} useRecaptchaNet="true" useEnterprise="true">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-lg-down mw-100 justify-content-center">
          <SaveMyDucatiModalBody hasError={saveModal.hasError} />
        </div>
      </GoogleReCaptchaProvider>
    </div>
  );
}

import { endpointResolver, retrieveTemplate, templateKeyMap } from "../utils/EndpointResolver";
import { isEmptyObject } from "../utils/Utils";

export const HTTP_STATUS_CODE = {
  SUCCESS: 200,
  SUCCESS_CREATE: 201,
  NO_CONTENT: 204,
  CONFLICT: 409,
};

export function buildMessage(url, status, statusText) {
  return `URL: ${url} \nStatus: ${status} \nText: ${statusText}`;
}

export function handleResponse(response) {
  if (response.status === HTTP_STATUS_CODE.SUCCESS || response.status === HTTP_STATUS_CODE.SUCCESS_CREATE) {
    return response.json();
  } else if (response.status === HTTP_STATUS_CODE.NO_CONTENT) {
    return response;
  } else if (response.status === HTTP_STATUS_CODE.CONFLICT) {
    console.warn(buildMessage(response.url, response.status, response.statusText));
    return response.json();
  }
  throw new Error(response.status);
}

export function buildHeader(data, isMTO) {
  let result = {};
  if (!isEmptyObject(data) && data && data.id_token) {
    result = {
      Authorization: isMTO ? data.id_token : "Bearer " + data.id_token,
    };
  }
  return result;
}

export function buildHeaderApiKey() {
  return {
    Authorization:
      "xt5hBPAS3BAj33d8wVwTgFHB465y588daXHPBuAvLMPZZFMesHE4MrTYzht3rkD8zcrKbj6V7wSwH3unKYh6XSwMaKjkb5m7TvgBW4AHMk7AkDZTRaY9J8nadh3C7BPe",
  };
}

export function addQueryParamsToRequestUrl(params) {
  let querystring = "?";

  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      params[key].forEach(p => {
        querystring += key + "=" + p + "&";
      });
    } else {
      querystring += key + "=" + params[key] + "&";
    }
  });
  querystring = querystring.substring(0, querystring.length - 1);
  return querystring;
}

export function getBody(dataBody) {
  return JSON.stringify(dataBody);
}

export const contentType = { "Content-Type": "application/json" };
export const contentTypeText = { "Content-Type": "text/plain" };
const contentTypePdf = { "Content-Type": "application/pdf" };
export const accept = { Accept: "application/json" };
export const acceptText = { Accept: "*/*" };

export const fetchLocalization = (country, language, data) => {
  const template = retrieveTemplate(templateKeyMap.localization);
  const url = endpointResolver(template, country, language);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };
  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchCompleteFamilies = (country, language, data) => {
  const template = retrieveTemplate(templateKeyMap.completeFamilies);
  const url = endpointResolver(template, country, language);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchConfigurationInit = (country, language, variantId, queryParam, data) => {
  const template = retrieveTemplate(templateKeyMap.configurationInit);
  const url = endpointResolver(template, country, language, variantId) + addQueryParamsToRequestUrl(queryParam);

  const options = { method: "POST" };
  options.headers = Object.assign({}, contentType, buildHeader(data));

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchBundle = (country, language, variantId, data) => {
  const template = retrieveTemplate(templateKeyMap.bundle);
  const url = endpointResolver(template, country, language, variantId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchAccessories = (country, language, variantId, data) => {
  const template = retrieveTemplate(templateKeyMap.accessories);
  const url = endpointResolver(template, country, language, variantId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchInviaDealer = (country, language, cid, data = {}) => {
  const template = retrieveTemplate(templateKeyMap.redirectDealer);
  const url = endpointResolver(template, country, language, cid);
  const options = !isEmptyObject(data)
    ? {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.id_token,
        },
      }
    : {
        method: "PUT",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchAggiungiAftermarket = (country, language, cid, variantId, force, body, data, quontity) => {
  const template = retrieveTemplate(templateKeyMap.addAccessory);
  const qty = quontity || 1;
  let url = endpointResolver(template, country, language, cid, variantId);
  url = url.replace("{force}", force);
  url = url.replace("{qty}", qty);

  const options = {
    method: "PUT",
    headers: contentType,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (data && !isEmptyObject(data)) {
    options.headers = Object.assign({}, contentType, buildHeader(data));
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchRimuoviAftermarket = (country, language, cid, variantId, force, data) => {
  const template = retrieveTemplate(templateKeyMap.removeAccessory);
  let url = endpointResolver(template, country, language, cid, variantId);
  url = url.replace("{force}", force);

  const options = {
    method: "DELETE",
    headers: contentType,
  };

  if (data && !isEmptyObject(data)) {
    options.headers = Object.assign({}, contentType, buildHeader(data));
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchSaveOnMyDucati = (country, language, cid, email, data = {}) => {
  const template = retrieveTemplate(templateKeyMap.saveOnMyDucati);
  const url = endpointResolver(template, country, language, cid);
  const body = {
    cid,
    email,
  };

  const options = {
    method: "PUT",
    headers: contentType,
    body: JSON.stringify(body),
  };

  if (data && !isEmptyObject(data)) {
    options.headers = Object.assign({}, contentType, buildHeader(data));
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchSendToEmail = (country, language, cid, email, challengeId = "", dealerCode) => {
  const sendToEmailTemplate = dealerCode?.length > 0 ? templateKeyMap.sendToEmailDealerCode : templateKeyMap.sendToEmail;
  const template = retrieveTemplate(sendToEmailTemplate);
  const url =
    dealerCode?.length > 0
      ? endpointResolver(template, country, language, cid, dealerCode)
      : endpointResolver(template, country, language, cid);

  const body = {
    cid,
    email,
    challenge_id: challengeId,
  };

  const options = {
    method: "PUT",
    headers: contentType,
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchOutfit = (country, language, variantId, data) => {
  const template = retrieveTemplate(templateKeyMap.outfit);
  const url = endpointResolver(template, country, language, variantId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchApparelDetails = (country, language, variantId, apparelId, data) => {
  const template = retrieveTemplate(templateKeyMap.apparelDetails);
  const url = endpointResolver(template, country, language, variantId, apparelId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchApparels = (country, language, variantId, data) => {
  const template = retrieveTemplate(templateKeyMap.apparels);
  const url = endpointResolver(template, country, language, variantId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchQuotation = (country, language, cid, body, data) => {
  const template = retrieveTemplate(templateKeyMap.quotation);
  const url = endpointResolver(template, country, language, cid);

  const options = {
    method: "PUT",
    headers: Object.assign({}, contentType, buildHeader(data)),
  };

  if (!isEmptyObject(body)) {
    options.body = getBody(body);
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchQuotationBase = (country, language, data) => {
  const template = retrieveTemplate(templateKeyMap.quotationBase);
  const url = endpointResolver(template, country, language);

  const options = {
    method: "GET",
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchCurrencyFormatter = (country, language, queryParam, data) => {
  const template = retrieveTemplate(templateKeyMap.currencyFormatter);
  const url = endpointResolver(template, country, language) + addQueryParamsToRequestUrl(queryParam);

  const options = {
    method: "GET",
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchSaveB2B = (country, language, cid, body, data) => {
  const template = retrieveTemplate(templateKeyMap.saveB2b);
  const url = endpointResolver(template, country, language, cid);

  const options = {
    method: "PUT",
    headers: Object.assign({}, contentType, buildHeader(data)),
  };

  if (!isEmptyObject(body)) {
    options.body = getBody(body);
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchInventory = (queryParam, data) => {
  const template = retrieveTemplate(templateKeyMap.inventory);
  const url = endpointResolver(template) + addQueryParamsToRequestUrl(queryParam);

  const options = {
    method: "GET",
    headers: Object.assign({}, buildHeaderApiKey()),
  };

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchRateData = body => {
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };

  const template = retrieveTemplate(templateKeyMap.rate);
  const url = endpointResolver(template);

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchPrintB2B = (country, language, cid, data) => {
  const template = retrieveTemplate(templateKeyMap.printB2B);
  const url = endpointResolver(template, country, language, cid);

  const options = {
    method: "GET",
    headers: buildHeader(data),
  };

  return fetch(url, options).then(response => response.blob());
};

export const fetchBundleDetails = (country, language, variantId, bundleId, data) => {
  const template = retrieveTemplate(templateKeyMap.bundleDetails);
  const url = endpointResolver(template, country, language, variantId, bundleId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => response.json());
};

export const fetchAccessoryDetails = (country, language, variantId, accessoryId, data) => {
  const template = retrieveTemplate(templateKeyMap.accessoryDetails);
  const url = endpointResolver(template, country, language, variantId, accessoryId);

  const options = {
    headers: Object.assign({}, buildHeader(data)),
  };

  return fetch(url, options).then(response => response.json());
};

export const fetchDealerNotes = (country, language, cid, body, data) => {
  const template = retrieveTemplate(templateKeyMap.dealerNotes);
  const url = endpointResolver(template, country, language, cid);

  const options = {
    method: "PUT",
    headers: Object.assign({}, contentType, buildHeader(data)),
  };

  if (!isEmptyObject(body)) {
    options.body = getBody(body);
  }

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchConfigReadOnly = ({ country, language, variantId, vid, data }) => {
  const template = retrieveTemplate(templateKeyMap.configurationInit);
  const url = endpointResolver(template, country, language, variantId) + addQueryParamsToRequestUrl({ vid });

  const options = { method: "GET" };
  options.headers = Object.assign({}, contentType, buildHeader(data));

  return fetch(url, options).then(response => handleResponse(response));
};

export const fetchVerifyRecaptcha = ({ token = "", siteKey = "", apiKey = "" }) => {
  const template = retrieveTemplate(templateKeyMap.verifyRecaptcha);
  const url = endpointResolver(template) + addQueryParamsToRequestUrl({ key: apiKey });
  const body = {
    event: {
      token,
      siteKey,
    },
  };
  const options = {
    method: "POST",
    headers: contentType,
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(response => handleResponse(response));
};

import React, { useRef, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "./NavbarConfigurator.module.scss";
import ExpandedMenu from "./expanded-menu/ExpandedMenu";
import { NavbarConfiguratorContext } from "../../context/NavbarConfiguratorContext";
import { isWebViewMode } from "../../utils/Utils";
import { WebViewModeContext } from "../../context/WebViewModeContext";
import { HumburgerMenuContext } from "../../context/HumburgerMenuContext";
import { useMobile } from "../../hooks/useMobile";
import { useViewport } from "../../hooks/useViewport";
import { cn } from "../../utils/cn";
import { NavbarStickyContext } from "../../context/NavbarStickyContext";
import { useScrambler } from "../../hooks/useScrambler";

function NavbarConfigurator({ itemList = [] }) {
  const isMobile = useMobile();
  const { navbarContent, setNavbarContent } = useContext(NavbarConfiguratorContext);
  const { menuIsShown } = useContext(HumburgerMenuContext);
  const [currentPosition, setCurrentPosition] = useState();
  const { webViewMode } = useContext(WebViewModeContext);
  const scollToRef = useRef([]);
  const { navbarStickyContent } = useContext(NavbarStickyContext);
  const [ref] = useViewport();
  const { scramblerStyle } = useScrambler();
  const [navList, setNavList] = useState(itemList);

  useEffect(() => {
    if (navbarContent?.currentNavItem) {
      setNavList(navbarContent?.navList || []);
      setCurrentPosition({
        step: navbarContent.currentNavItem.step,
        position: navbarContent.currentNavItem.position,
        scollToRefIndex: navbarContent.currentNavItem.scollToRefIndex,
      });

      if (scollToRef.current.length > 0 && isMobile) {
        scollToRef.current[navbarContent.currentNavItem.scollToRefIndex].scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [isMobile, navbarContent?.currentNavItem, navbarContent?.navList]);

  function resolveNavItemLabel(index, label) {
    return (index + 1).toString().concat(". ").concat(label);
  }

  function updatePosition(item, scollToRefIndex) {
    const current = { ...item };
    current.scollToRefIndex = scollToRefIndex;
    const newNavbarContent = { ...navbarContent };
    newNavbarContent.currentNavItem = current;
    setNavbarContent(newNavbarContent);
  }

  const navbarClasses = cn("container-fluid p-0", style.navbar, {
    [style.StickyNavbarViewMode]: navbarStickyContent?.isSticky && isWebViewMode(webViewMode),
    [style.StickyNavbar]: navbarStickyContent?.isSticky && !isWebViewMode(webViewMode),

    "d-none": menuIsShown?.hideNav,
  });

  return (
    <div ref={ref} id="navbar-wrapper">
      <div id="navbarConfigurator" className={navbarClasses}>
        <div className="row m-auto">
          <div
            id="nav-container"
            className={cn("col-10 col-sm-11 col-md-11 col-lg-12 col-xl-12 p-0", {
              [style["nav-container--scrambler"]]: scramblerStyle,
              [style["nav-container"]]: !scramblerStyle,
            })}
          >
            <ul className={"nav  ".concat(isMobile ? style["nav-content"] : style["nav-content-desktop"])}>
              {currentPosition &&
                navList.map((item, index) => {
                  const navItemLabel = resolveNavItemLabel(index, item.step);
                  const itemId = "item".concat(index + 1);
                  const active = item.position === currentPosition.position ? style["active-button"] : "";
                  return (
                    <li ref={el => (scollToRef.current[index] = el)} id={navItemLabel} key={navItemLabel} className="nav-item">
                      <button
                        id={itemId}
                        type="button"
                        className={`btn ${style["nav-button"]} ${active}`}
                        onClick={() => {
                          updatePosition(item, index);
                        }}
                      >
                        {navItemLabel}
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>
          <ExpandedMenu itemList={navList} currentPosition={currentPosition} updatePosition={updatePosition} isNavbarConfig={true} />
        </div>
      </div>
    </div>
  );
}

NavbarConfigurator.propTypes = {
  itemList: PropTypes.array,
};

export default NavbarConfigurator;
